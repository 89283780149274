<template>
        <div class="liuyanItem">
            <div class="touxiang" :style="{backgroundImage: `url(${touxiangPath})`}"></div>
            <div class="content" >
            <div class="name">{{ name }}</div>  
            <div class="text">{{content}}</div>
            <div class="inform">
                <div class="time" :style="{visibility: ''}">{{time}}</div>
                <!-- <div class="addNum">100</div>
                <div class="subNum">0</div> -->
                <div class="hf" @click="hf">{{hfExp}}</div>
            </div> 
            <div class="hfText" v-if="hfExpand">
                <textarea class="hfInput" v-model="huifuContent"></textarea>
                <div class="subB" @click="subB">发送</div>
            </div>
            <huifu v-for="(v,i) in huifus" :name="v.name" :content="v.content" :touxiangPath="v.touxiangPath" :time="v.time"/>
            <!-- <div class="huifuContent" >
                <div class="huifuTouxiang" ></div>
                <div class="huifuText">
                    <span class="huifuName">鑫可可</span>  
                    {{subText}}
                    <div class="huifuInform">
                        <div class="time">2024-05-30 01:22</div>
                        <div class="addNum">100</div>
                        <div class="subNum">0</div>                     -->
                    <!-- </div> 
                </div>
                <div class="expand">共1条回复,点击查看</div>
            </div> -->
        </div>
        <div class="Hline">

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
import { mapState } from 'vuex';
import huifu from './huifu.vue';
var ip="introl-whc.cn";//localhost:8000  introl-whc.cn
    export default {
        name: 'contentItem',
        data(){
            return {
                hfExpand: false,
                hfExp: "回复",
                maxWidth: 50,
                mainText: "给自己的留言: 希望你不负韶华, 不断学习,不断进步, 成为我所希望的那样无所不能! 希望你总能被周围的人所善待, 每天都能忙碌而开心的生活着! ",
                subText: "一定会的! ! !",
                huifuContent: "",
                huifus:[],
                
            }
        },
        components:{
            huifu,
        },
        computed:{
            ...mapState(['userName']),
        },
        props:{
            touxiangPath:{
                type: String,
                required: true
            },
            name:{
                type: String,
                required: true
            },
            content:{
                type: String,
                required: true
            },
            time:{
                type:String,
                required:true,
            },
            liuyanId:{
                type: Number,
                required: true,
            }
        },
        async mounted(){
            const res=await axios.post(`https://${ip}/xinkeke/huifuAll`, {
                liuyanId: this.liuyanId,
            });
            res.data.forEach(element => {
                let newObj=new Object();
                newObj.name=element.username;
                newObj.time=element.time.replace('T'," ");
                newObj.content=element.content;
                newObj.touxiangPath=`https://${ip}/${element.touxiangPath}`;
                this.huifus.push(newObj);
            });
        },
        methods:{
            hf(){
                if(!this.hfExpand){
                    this.hfExpand=true;
                    this.hfExp="收起";
                }
                else{
                    this.hfExpand=false;
                    this.hfExp="回复";
                }
            },
            async subB(){
                if(this.userName==""){
                    alert("请先登录!");
                    return;
                }
                if(this.huifuContent.length<=1){
                    alert('请正确留言！');
                    return;
                }
                const res=await axios.post(`https://${ip}/xinkeke/huifu`, {
                    content: this.huifuContent,
                    username: this.userName,
                    touxiangPath:'',
                    created_at: '',
                    liuyanId: this.liuyanId,
                });
                this.hfExpand=false;
                this.hfExp="回复";

                this.huifus.length=0;
                const re=await axios.post(`https://${ip}/xinkeke/huifuAll`, {
                    liuyanId: this.liuyanId,
                });
                re.data.forEach(element => {
                    let newObj=new Object();
                    newObj.name=element.username;
                    newObj.time=element.time.replace('T'," ");
                    newObj.content=element.content;
                    newObj.touxiangPath=`https://${ip}/${element.touxiangPath}`;
                    this.huifus.push(newObj);
                });
            }
        }
    }
</script>

<style scoped>
.hfText{
    width: 45vw;
    position: relative;
    .hfInput{
        all:unset;
        width:39vw;
        height:10vh;
        margin-top: 1vh;
        margin-left: 0.5vw;
        border: 1px solid black;
        border-radius: 1vh;
        padding: 0.5vh 0.5vw;
        background-color: white;
        color: black;
        font-size: 1.5vh; 
        font-family: initial;
        overflow-y: scroll;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .hfInput::-webkit-scrollbar {
        width: 0.2vw; 
        background-color: #f5f5f5;   
        display: none;
    }
    .hfInput::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .hfInput::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5vh; 
    }
    .hfInput::-webkit-scrollbar-button {
        display: none; 
    }
    .subB{
        position: absolute;
        width: 5vw;
        height: 3vh;
        background-color: rgb(3, 3, 181);
        left: 34.5vw;
        top: 8vh;
        text-align: center;
        line-height: 3vh;
        border-radius: 1vh;
        box-shadow: 0.1vh 0.5vh 0.2vh gray;
        cursor: pointer;
        &:hover{
            background-color: rgb(4, 4, 168);
        }
        &:active{
            background-color: rgb(2, 2, 100);
            box-shadow: 0.1vh 0.3vh 0.1vh black;
        }
    }
}
.time,.addNum,.subNum,.hf{
    font-size: 1.4vh;
}
    .hf{
       cursor: pointer; 
    }
    .expand{
        margin-top: 1vh;
        font-size: 1.4vh;
        cursor: pointer;        
    }
    .touxiang{
        width: 8vh;
        height: 8vh;
        border-radius: 50%;
        margin-top: 2vh;
        background-position:center;
        background-repeat:no-repeat;
        background-size:cover;
        display: inline-block;
        margin-left: 1.5vw;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .liuyanItem{
        width: 50vw;
        border-radius: 3vh; 
        border: 2px solid gray;
        border-radius: 2vh;        
        display: flex;
        margin-top: 1vh;
        &:hover{
            border-color: purple;
        }
        .content{
            width: 41vw;
            margin-top: 3.5vh;
            margin-left: 0.6vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: space-between;
            line-height: 3vh;
            .text{
                margin-top: 1vh;
                font-size: 2vh;
            }
            .inform,.huifuInform{
                width: 41vw;
                height: 2.5vh;
                display: flex;
                justify-content: space-between;
                margin-top: 2vh;
                font-size: 2vh;
                font-weight: lighter;
            }
            .name{
                font-size: 1.4vh;
                color:red;
            }
            .huifuContent{
                display: flex;
                justify-content: flex-start;
                align-content: flex-start;
                flex-wrap: wrap;
                .huifuTouxiang{
                    width: 4vh;
                    height: 4vh;
                    border-radius: 50%;
                    padding: 0;
                    border-width: 0;
                    display: inline-block;
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-top: 0.5vh;
                    background-image: url("/public/assets/touxiang.jpg");
                    background-size:contain;
                }
                .huifuName{
                    height: 2vh;
                    margin-right: 1.2vw;
                    color: red;                    
                    font-size: 1.4vh;
                }
                .huifuText{
                    font-size: 2vh;
                    display: inline-block;
                    margin-top: 1vh;
                    margin-left: 0.3vw;
                    line-height: 3vh;
                    width: 38.5vw;
                    .huifuInform{
                        width: 38vw;
                        height: 2.5vh;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 2vh;
                    }
                }
            }
        }
    }
</style>