<template>
    <div class="contentRight">
        <div class="xinkeke">
            <img src="/assets/touxiang.jpg" class="touxiang">
            <div class="myName"><span>鑫可可</span></div>
            <div class="tags">
                <span class="tag">技术宅</span>
                <span class="tag">javascript</span>
                <span class="tag">男生</span>
                <span class="tag">javascript</span>
                <span class="tag">javascript</span>
                <span class="tag">javascript</span>
                <span class="tag">javascript</span>
            </div>
            <div class="data">
                <span>文章 100</span>
                <div class="lineV"></div>
                <span>点赞 100</span>
                <div class="lineV"></div>
                <span>阅读 100</span>
                <div class="lineV"></div>
                <span>评论100</span>
            </div>
        </div>
        <div class="server">
            <span>服务器状态</span>
            <div class="form">
                <div class="status status1">
                    <label>CPU</label>
                    <div class="barBack"></div>
                    <div class="bar barCPU"></div>
                </div>
                <div class="status status2">
                    <label>内存</label>
                    <div class="barBack"></div>
                    <div class="bar barNeicun"></div>
                </div>
                <div class="status status3">
                    <label>磁盘</label>
                    <div class="barBack"></div>
                    <div class="bar barDisk"></div>    
                </div>
                <div class="status status4">
                    <label>网速</label>
                    <div class="barBack"></div>
                    <div class="bar barNet"></div>
                </div>
            </div>
        </div>        
        <div class="gonggao">
            <span>公告</span>
            <span class="contentGonggao">我将一直更新和维护这里的内容，在这里你可以获取最新的技术文章、项目更新和学习资料。</span>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'contentRight',
        data(){
            return {
                
            }
        }
    }
</script>

<style scoped>
    .contentPos{
        width: 15vw;
        border-radius: 3vh;
        height: 94vh;
        margin-left: 0.5vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    .lineH{
        height: 0.5vh;
        width: 12vw;
        background-color: black;
        margin-top: 3vh;
    }
    .lineV{
        height: 4vh;
        width: 0.1vw;
        display: inline-block;
        background-color: black;
        margin-left: 0.3vw;
    }
    .data{
        font-size: 1.5vh;
        width: 10vw;
        margin-top: 3vh;
        display: flex;
        span{
            display: inline-block;
            width: 3vw;
            margin-left: 0.2vw;
            height: 2vh;
            line-height: 2vh;
        }
    }
    .server{
        margin-top: 4vh;
        font-size: 3.0vh;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        height: 25vh;
        .form{
            font-size: 3vh;
            width: 14vw;
        }
        .bar{
            width: 10vw;
            height: 2vh;
            background-color: red;
            margin-left: 0.4vw;
            display: inline-block;
            border-radius: 1.2vh;
            margin-top: 0.5vh;
            border-width: 0.1vh;
            border-color: black;
            border-style: solid;
            position: absolute;
            left: 3vw;
            z-index: 2;
        }
        .barBack{
            width: 10vw;
            height: 2vh;
            background-color: gray;
            margin-left: 0.4vw;
            display: inline-block;
            border-radius: 1.2vh;
            margin-top: 0.5vh;
            border-width: 0.1vh;
            border-color: black;
            border-style: solid;
            position: absolute;
            left: 3vw;
            z-index: 1;

        }
        .barCPU{
            width: 2vw;
        }
        .status{
            margin-top: 2vh;
            display: flex;
            position: relative;
            font-size: 2vh;
        }
    }
    .gonggao{
        display: flex;
        align-content: flex-start;
        margin-top: 3vh;
        font-size: 3.0vh;
        flex-wrap: wrap;
        justify-content: center;
        .contentGonggao{
            width: 13vw;
            font-size: 2vh;
            font-size:lighter;
            display: block;
            text-indent: 2vw;
            margin-top: 1vh;
        }

    }
    .contentRight{
        width: 15vw;
        background-color: rgba(0, 255, 255, 0.8);
        border-radius: 3vh;
        height: 94vh;
        margin-left: 0.5vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;

        .xinkeke{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-content: flex-start;
            .touxiang{
                width: 11vh;
                height: 11vh;
                border-radius: 50%;
                margin-top: 2vh;
                display: block;
            }
            .myName{
                display: block;
                width: 10vw;
                font-size: 2.5vh;
                margin-top: 0.5vh;
                span{margin-left: 3.1vw;}
            }
            .tags{
                width: 13vw;
                font-size: 1.5vh;
                margin-top: 2vh;
                display: flex;
                flex-wrap: wrap;
                background-color: antiquewhite;
            }
        }
    }

</style>