<template>
    <div class='login'>
      
        <form class="form">
            <div class="selection">
                <div class="deng selectionItem" @click="denglu">登录</div>
                <div class="zhu selectionItem" @click="zhu">注册</div>
            </div>
            <div class="deng" v-if="deng">
                <input class="nameUser inpu" name="nameUser" placeholder="输入帐号名" autocomplete="off">
                <input class="mima inpu" name="mima" placeholder="输入密码" autocomplete="off">
                <div class="wangji"><span class="wang">忘记密码?</span></div>
            </div>
            <div class="zhu" v-else="false">
                <input class="nameUser inpu" name="nameUser" placeholder="输入帐号名" autocomplete="off">
                <input class="mail inpu" name="mail" placeholder="输入邮箱" autocomplete="off">
                <input class="mima inpu" name="mima" placeholder="输入密码" autocomplete="off">                
            </div>
            <div class="subButton" @click="changeColor" :style="buttuonStyle">
                {{ submitButton }}
            </div>
        </form>
       
    </div>
</template>

<script>

        export default {
        name: 'login',
        data(){
            return {
                deng: true,
                submitButton: "登录",
                bacc: 'aquamarine',
                buttuonStyle: {
                        backgroundColor: '',
                }
            }
        },
        methods:{
            changeColor(){
                this.buttuonStyle.backgroundColor="blue";
                setTimeout(() => {
                    this.buttuonStyle.backgroundColor="";
                }, 100);
            },
            denglu(){
                this.deng=true;
               this.submitButton="登录";
             
            },
            zhu(){
                this.deng=false;
                this.submitButton="注册";
            }
        },
        components:{
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'myFont1';
        src: url(/public/assets/myFont1.ttf) format('truetype');
    }

    .subButton{
        width:21vw;
        height: 5vh;
        margin-top: 5vh;
        text-align: center;
        line-height: 5vh;
        font-size: 3vh;
        border-radius: 2vh;
        border: 0.1vh solid;
        
    }

    .login{
        width: 100vw;
        height: 100vh;
        position: fixed;
        left:0;
        top:0;
        background-color: rgba(48, 47, 47,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form{
        width:30vw;
        height: 50vh;
        background-color: rgb(228, 226, 226);
        border-radius: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        .zhu,.deng{
            width: 30vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;
        }
    }
    .inpu{
        all: unset;
        width:20vw;
        height: 5vh;
        margin-top: 3vh;
        border-width: 0.3vh;
        border-bottom-style: solid;
        padding-left: 0.5vw;      
        font-size: 2vh;
    }
    .selection{
        width:30vw;
        display: flex;
        justify-content: center;
        margin-top: 5vh;
        .selectionItem{
            width: 4vw;
            height: 4vh;
            cursor: pointer;
            margin-left: 3vw;
            margin-right: 3vw;
            font-family: 'myFont1';
            font-size: 3.5vh;
            text-align: center;
            line-height: 4vh;
        }
    }    
    .wangji{
        width: 20vw;
        height: 7vh;
        margin-top: 1vh;
        text-align: right;
        color: blue;
        font-size: 1.5vh;
        .wang{
            cursor: pointer;
        }
    }
</style>