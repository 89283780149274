<template>
    <div class="starry-sky-container">
      <canvas ref="canvas" width="800" height="600"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        ctx: null,
        stars: [],
        starConfig: {
          minRadius: 0.5,
          maxRadius: 1
        },
        animationFrameId: null,
      };
    },
    mounted() {
      this.ctx = this.$refs.canvas.getContext('2d');
      this.createStars();
      this.animate();
    },
    beforeUnmount(){
    
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId); 
      }
      this.ctx.value = null; 

    },
    methods: {
      createStars() {
        for (let i = 0; i < 200; i++) {
          this.stars.push(this.createStar());
        }
      },
      createStar() {
        return {
          x: Math.random() * this.$refs.canvas.width,
          y: Math.random() * this.$refs.canvas.height,
          radius: Math.random() * (this.starConfig.maxRadius - this.starConfig.minRadius) + this.starConfig.minRadius,
          speed: Math.random() * 0.3 + 0.2,
          opacity: Math.random() * 0.05 + 0.95
        };
      },
      animate() {
        this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        this.drawStars();
        this.animationFrameId=requestAnimationFrame(this.animate);
      },
      drawStars() {
        this.stars.forEach((star, index) => {
          this.ctx.beginPath();
          this.ctx.arc(star.x, star.y, star.radius, 0, 2 * Math.PI, false);
          if(Math.random()>=0.5) this.ctx.fillStyle = `rgba(0, 0, 255, ${star.opacity})`;
          else this.ctx.fillStyle = `rgba(241, 189, 14, ${star.opacity})`;
          this.ctx.fill();
          this.updateStar(star, index);
        });
      },
      updateStar(star, index) {
        star.y -= star.speed*0.8;
        star.x +=star.speed*0.6;
        if (star.y <= 0) {
          star.y = this.$refs.canvas.height;
        }
        if(star.x>=this.$refs.canvas.width){
            star.x=0;
        }
      },
    }
  };
  </script>
  
  <style scoped>
 
  .starry-sky-container {
    width: 100%;
    height: 100vh;
    background-color: black;
    overflow: hidden;
    position: relative;
    position: fixed;
    left: 0;
    top:0;
    z-index: 0;
  }
  canvas {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top:0;
    z-index: 0;
  }
  .foot{
        width: 100vw;
        background-color: rgb(134, 10, 10);
        height: 10vh;
  }
  </style>
  