<template>
    <canv/>
    <div class="zuopingC">
        <div class="contentPos"></div>
        <div class="contentLeft">
            <div class="zuopingNames">
                <div class="zuopingItem" v-for="(v1,i1) in titles" :key="v1.title">
                    <div class="zuopingName" @click="changeM(i1)">{{v1.title}}</div>
                </div>
            </div>
        </div>
        <div class="contentM" ref="cM">
            <component :is="currentComponents[curIdx1]" />       
        </div>
        <contentRightFixed fixed="true"/>
            <div class="foot">
            <a class="a2" href="https://beian.miit.gov.cn/" target="_blank">黔ICP备2024032689号-1</a>
            <img class="im" src="/assets/beian.png">
            <a class="a1" href="https://beian.mps.gov.cn/#/query/webSearch?code=52050002000116" rel="noreferrer" target="_blank">贵公网安备52050002000116</a>     
            </div>
</div>
</template>

<script>
    import contentItems from './contentItems';
    import contentRightFixed from './contentRightFixed';
    import zuoping1 from './zuoping/zuoping1.vue';
    import canv from "./canv.vue"
    import zuoping2 from './zuoping/zuoping2.vue';
    import zuoping3 from './zuoping/zuoping3.vue';
    import zuoping4 from './zuoping/zuoping4.vue';
    import zuoping5 from './zuoping/zuoping5.vue';
    import zuoping6 from './zuoping/zuoping6.vue';
    import topB from "./topB.vue";

    export default {
        name: 'contentLeft',
        data(){
            return {
                titles:[
                    {
                        title: "智能日期管家",
                    },
                    {
                        title: "3D魂系游戏制作",
                    },
                    {
                        title: "OpenGL无引擎渲染场景",
                    },
                    {
                        title: "Tdn",
                    },
                    {
                        title: "多手机联合推理大模型",
                    },
                ],
                rot:[
                    {transform: "",},
                    {transform: "",},
                    {transform: "",},
                ],
                ifDis:[
                    {display: "none"},
                    {display: "none"},
                    {display: "none"},
                ],
                currentComponents:['zuoping3','zuoping1','zuoping2','zuoping4','zuoping5','zuoping6'],
                curIdx1:0,
                curIdx2:0,
            }
        },
        components:{
            contentRightFixed,
            canv,
            zuoping1,zuoping2,zuoping3,zuoping4,zuoping5,zuoping6,topB,
        },
        methods:{
            changeM(i1){
                this.curIdx1=i1;
            }
        },
    }
</script>

<style scoped>
    .dayu{
        width: 0.7vw;
        height:0.9vh;        
        margin-top: 0.5vh;
        padding-right: 0.3vw;
        cursor: pointer;
    }
    .zuopingName{
        display: inline-block;
        font-size: 1.8vh;
        cursor: pointer;
        width: 8.0vw;
        white-space: nowrap;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
    }
    .subNames{
        width: 9vw;
        margin-left:1vw;
    }
    .subName{
        font-size: 1.6vh;
        margin-left: 0vw;
        text-align: left;
        width: 8vw;        
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
        cursor: pointer;
    }
    .zuopingItem{
        width: 10vw;
        margin-top: 1vh;
        text-align: left;
        display: flex;
        justify-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 5vw;
    }
    .zuopingNames{
        width: 15vw;
        height: 85vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
    }
    .contentPos{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;

        width: 15vw;
        height: 94vh;
        margin-left: 8vw;
        border-radius: 3vh;
        
    }
    .contentLeft{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;

        width: 15vw;
        height: 92vh;
        border-radius: 3vh;
        margin-left: 8vw;
        
        position: fixed;
        top:5vh;
        
        border: 2px solid gray;
        border-radius: 2vh;
        margin-top: 2vh;

        color:white;
        &:hover{
            border-color: purple;
        }
    }    
   
    .pos{
        width: 100vw;
        height: 6vh;
        background-color: red;
    }
    .contentM{
        width:50vw;
        min-height: 120vh;
        margin-left: 0.5vw;
        border-radius: 3vh;
        border: 2px solid gray;
        border-radius: 2vh;
        margin-top: 1vh;
        height: auto;
        &:hover{
            border-color: purple;
        }
        &::after {
            content: '';
            display: block;
        }
    }

    .foot{
        width: 100vw;
        height: 16vh;
        background-color: rgba(0,0,0,0);
        z-index: 100;
        display:flex;
        justify-content: center;
        line-height: 6vh;
        vertical-align: bottom;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 18vh;

        .im{
            display: block;
            margin-right: 1vw;
        }
        .a1,.a2{
            display: inline-block;
            height: 3vh;
            text-align: center;
            line-height: 3vh;
            font-size: 2vh;
            color: #ffa200;
            margin-left: 2vw;
        }
        .a2{
            width: 100vw;   
            margin-left: 1vw;
            margin-top: 1vh;
        }
        .a1{
            margin-left: 0vw;
            margin-top: 1vh;
        }
    }        
    .zuopingC{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        margin-top: 6vh;
    }
    .zuopingC::-webkit-scrollbar {
        width: 0.3vw; 
        background-color: #f5f5f5;   
    }
    .zuopingC::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .zuopingC::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5vh; 
    }
    .zuopingC::-webkit-scrollbar-button {
        display: none; 
    }
</style>