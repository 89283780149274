<template>
    <div class="content" :style="{zIndex:zidx}">
    <pre>
第一章 vue3简介
1.vue3简介
    2020年9月18日，vue3发布3.0版本，代号大海贼时代来临，One Piece
    特点：
    无需构建步骤，渐进式增强静态的 HTML
    在任何页面中作为 Web Components 嵌入
    单页应用 (SPA)
    全栈 / 服务端渲染 (SSR)
    Jamstack / 静态站点生成 (SSG)
    开发桌面端、移动端、WebGL，甚至是命令行终端中的界面
2.Vue3带来了什么
    打包大小减少40%
    初次渲染快55%，更新渲染快133%
    内存减少54%
3.分析目录结构
    main.js中的引入
    在模板中vue3中是可以没有根标签了，这也是比较重要的改变
    应用实例并不只限于一个。createApp API 允许你在同一个页面中创建多个共存的 Vue 应用，而且每个应用都拥有自己的用于配置和全局资源的作用域。
4.安装vue3的开发者工具
方式一： 打开chrome应用商店，搜索vue： 里面有个Vue.js devtools，且下面有个角标beta那个就是vue3的开发者工具
方式二： 离线模式下，可以直接将包丢到扩展程序
<img src="/assets/jie21.png">
    </pre>
    </div>
</template>

<script>

export default {
        name: 'content',
        data(){
            return {
                pdfUrl: '/assets/HTML4.pdf'
            }
        },
        components:{
        },
        props:{
            zidx:Number
        }
    }
</script>

<style scoped>
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
img{
    width: 100%;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        float: left;
        left: 0;
        top: 0;
        height: auto;
    }
</style>