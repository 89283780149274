<template>
    <div class='fankui'>
      
        <textarea class="fankuiContent"></textarea>
       
    </div>
</template>

<script>

        export default {
        name: 'login',
        data(){
            return {
                deng: true,
                submitButton: "登录",
                bacc: 'aquamarine',
                buttuonStyle: {
                        backgroundColor: '',
                }
            }
        },
        methods:{
            changeColor(){
                this.buttuonStyle.backgroundColor="blue";
                setTimeout(() => {
                    this.buttuonStyle.backgroundColor="";
                }, 100);
            },
            denglu(){
                this.deng=true;
               this.submitButton="登录";
             
            },
            zhu(){
                this.deng=false;
                this.submitButton="注册";
            }
        },
        components:{
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'myFont1';
        src: url(/public/assets/myFont1.ttf) format('truetype');
    }

    .subButton{
        width:21vw;
        height: 5vh;
        margin-top: 5vh;
        text-align: center;
        line-height: 5vh;
        font-size: 3vh;
        border-radius: 2vh;
        border: 0.1vh solid;
        
    }

    .fankui{
        width: 30vw;
        height: 20vh;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 2vw;
        top: 7vh;

    }
    .form{
        width:50vw;
        height: 50vh;
        background-color: rgb(228, 226, 226);
        border-radius: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        margin-bottom: 30vh;
        .zhu,.deng{
            width: 30vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;
        }
    }
    .inpu{
        all: unset;
        width:20vw;
        height: 5vh;
        margin-top: 3vh;
        border-width: 0.3vh;
        border-bottom-style: solid;
        padding-left: 0.5vw;      
        font-size: 2vh;
    }
    .selection{
        width:30vw;
        display: flex;
        justify-content: center;
        margin-top: 5vh;
        .selectionItem{
            width: 4vw;
            height: 4vh;
            cursor: pointer;
            margin-left: 3vw;
            margin-right: 3vw;
            font-family: 'myFont1';
            font-size: 3.5vh;
            text-align: center;
            line-height: 4vh;
        }
    }    
    .pos-con{
        visibility: hidden;
    }
</style>