<template>
    <div class="content" :style="{zIndex:zidx}">
    <pre>
第一章 JavaScript简介
    1.1、JavaScript的起源
    JavaScript诞生于1995年，它的出现主要是用于处理网页中的前端验证。所谓的前端验证，就是指检查用户输入的内容是否符合一定的规则。比如：用户名的长度，密码的长度，邮箱的格式等。但是，有的同学可能会有疑问，这些验证，后端不也可以进行验证吗？确实，后端程序的确可以进行这些验证，但你要清楚，在1995年那个年代，网速是非常慢的，向后端发送一个请求，浏览器很久才能得到响应，那这无疑是一种非常不好的用户体验。

    为了解决前端验证的问题，当时的浏览器巨头NetScape（网景）公司就开发出一种脚本语言，起初命名为LiveScript，后来由于SUN公司的介入更名为了JavaScript。但是你要清楚，Java和JavaScript是没有什么关系的，只不过当时Java非常流行，为了蹭热度，才将LiveScript更名为JavaScript，它们的关系就像雷锋和雷峰塔的关系一样，没啥关系。

    但是，浏览器开发商不止网景一家，还有一个大家都知道的公司，微软公司，它们的主打产品是IE（Internet Explorer）浏览器，当网景公司的Netscape Navigator浏览器推出JavaScript语言时，微软就急了啊，好家伙，人网景都推出了专门用于前端验证的语言，不仅大大减少了后端程序的压力，还提高了用户的体验。我微软这么大的公司不也得整一个，在1996年，微软公司在其最新的IE3浏览器中引入了自己对JavaScript的实现JScript。

    于是在市面上存在两个版本的JavaScript，一个网景公司的JavaScript和微软的JScript，虽然当时浏览器的巨头是网景，但是网景的浏览器是收费的，虽然微软的IE浏览器在全球的市场份额远远不及网景，但是微软的拳头产品是Windows操作系统，每一个操作系统都自带一个IE浏览器并且免费，那么，未来的发展大家可能也想到了，网景让微软给干倒闭了，1998年11月，网景被美国在线（AOL）收购。

    老大哥就是老大哥，为了抢先获得规则制定权，网景最先将JavaScript作为草案提交给欧洲计算机制造商协会，也就是ECMA组织，希望能将JavaScript做成行业标准，最终在网景、SUN以及微软等公司的参与下，由一众程序员和相关组织人员组成的第39技术委员会也就是TC39发布了ECMA-262标准，这个标准定义了名为ECMAScript的全新脚本语言，为啥又来了个ECMAScript？

    因为Java是SUN的商标，SUN授权了NetScape可以叫JavaScript，但是ECMA没有SUN的授权就不能叫JavaScript，哪怕NetScape成员特别希望ECMA把它叫做JavaScript，但是ECMA也有成员并不希望这个标准就叫JavaScript，总之经过几轮磋商和博弈，ECMAScript这个名字就定下来。

    我们可以简单看一下历史事件发展表：
    <img src="/assets/jie1.png" >
    1.2、JavaScript的组成
    ECMAScript是一个标准，而这个标准需要由各个厂商去实现，不同的浏览器厂商对该标准会有不同的实现。
    <img src="/assets/jie2.png" >
    我们已经知道ECMAScript是JavaScript标准，所以一般情况下这两个词我们认为是一个意思。但是实际上JavaScript的含义却要更大一些。一个完整的JavaScript实现应该由以下三个部分构成：
    <img src="/assets/jie3.png" >
    由此我们也知道了我们所要学习的内容就是这三部分，它们具体的含义后边章节会具体介绍。
    1.3、JavaScript的特点
解释型语言

JavaScript是一门解释型语言，所谓解释型值语言是指不需要被编译为机器码在执行，而是直接执行。由于少了编译这一步骤，所以解释型语言开发起来尤为轻松，但是解释型语言运行较慢也是它的劣势。不过解释型语言中使用了JIT技术，使得运行速度得以改善。

动态语言

JavaScript是一门动态语言，所谓的动态语言可以暂时理解为在语言中的一切内容都是不确定的。比如一个变量，这一时刻是个整型，下一时刻可能会变成字符串了。当然这个问题我们以后再谈。不过在补充一句动态语言相比静态语言性能上要差一些，不过由于JavaScript中应用的JIT技术，所以JavaScript可能是运行速度最快的动态语言了。

类似于 C 和 Java 的语法结构

JavaScript的语法结构与C和Java很像，向for、if、while等语句和Java的基本上是一模一样的。所以有过C和Java基础的同学学习起来会轻松很多。不过JavaScript和与Java的关系也仅仅是看起来像而已。

基于原型的面向对象

JavaScript是一门面向对象的语言。啥是对象？下次聊。

Java也是一门面向对象的语言，但是与Java不同JavaScript是基于原型的面向对象。啥是原型？下次聊。

严格区分大小写

JavaScript是严格区分大小写的，也就是abc和Abc会被解析器认为是两个不同的东西。
    </pre>
    </div>
</template>

<script>

export default {
        name: 'content',
        data(){
            return {
                pdfUrl: '/assets/HTML4.pdf'
            }
        },
        components:{
        },
        props:{
            zidx:Number
        }
    }
</script>

<style scoped>
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
img{
    width: 100%;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        float: left;
        left: 0;
        top: 0;
        height: auto;
    }
</style>