<template>
    <div class="content" :style="{zIndex:zidx}">
        <div class="pause"  @click="changeS" v-if="!play"></div>

    <pre>
                        <span>智能日期管家</span>

演示视频:
<video  src="/lingXi.mp4" @click="changeS" ref='v'></video>
    这个项目主要功能是帮助用户保存和管理用户指定的重要日期,包括朋友生日,纪念日,节日等等,并且在合适的时机去提醒并帮助用户生成对应的祝福文字,祝福海报或者祝福视频.但是由于大模型还在调试准备中,目前就只能生成祝福文本.
    项目完全由我一个人全栈开发,前端使用的是Wxml,Wxss和Javascript,后端使用的是Python和FastAPI,呈现形势目前是微信小程序.工作流程主要是用户授权微信帐号信息,然后填写重要日期信息,然后通过axios发送数据,后端将数据存入Mysql.之后后端定期检测用户日期信息,如果发现日期将到,会在用户指定的提醒时间(如提前一天,提前两天等)给用户发送提醒消息.用户进入应用后就可以直接输入文本信息,然后后端返回生成的祝福信息.
    </pre>
    </div>
</template>

<script>

export default {
        name: 'content',
        data(){
            return {
                play: false,
            }
        },
        components:{
        },
        methods:{
            changeS(){
                if(!this.play) {
                    this.$refs.v.play();
                    this.play=true;
                }
                else{
                    this.$refs.v.pause();
                    this.play=false;   
                }
            }   
        }
    }
</script>

<style scoped>
span{
    font-size:4vh;
    font-weight:border;
}
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        color: white;
        float: left;
        left: 0;
        top: 0;
        height: auto;
        position:relative;

        video{
            object-fit: contain;
        }
    }
    video {
        width: 30%;
        height: auto;
        object-fit: contain;
        cursor: pointer; /* 添加光标样式，提示用户可以点击 */
        float:left;
        margin-right:2vw;
    }
    .pause{
    position:absolute;
    width: 5vw;
    height:5vw;
    background-image:url("/public/assets/pause.png");
    background-size:contain;
    opacity: 80%;
    left: 4.5vw;
    top:34vh;
    &:hover{
        width: 6vw;
        height:6vw;
    }
}
</style>