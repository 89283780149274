<template>
    <div class="contentMiddle">
        <contentItem1 :contentTitle="items[0].contentTitle" :contentText="items[0].contentText"  :imgUrl="items[0].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[1].contentTitle" :contentText="items[1].contentText"  :imgUrl="items[1].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[2].contentTitle" :contentText="items[2].contentText"  :imgUrl="items[2].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[3].contentTitle" :contentText="items[3].contentText"  :imgUrl="items[3].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[4].contentTitle" :contentText="items[4].contentText"  :imgUrl="items[4].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[5].contentTitle" :contentText="items[5].contentText"  :imgUrl="items[5].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[6].contentTitle" :contentText="items[6].contentText"  :imgUrl="items[6].imgUrl"/>
        <div class="pos"></div>
        <contentItem1 :contentTitle="items[7].contentTitle" :contentText="items[7].contentText"  :imgUrl="items[7].imgUrl"/>
    </div>
</template>

<script>
    import contentItem1 from './contentItems/contentItem1.vue';
    import contentItem2 from './contentItems/contentItem2.vue';
    export default {
        name: 'contentLeft',
        data(){
            return {
                items:[
                    {
                        contentTitle:"Petals实现大模型的分布式推理",
                        contentText:"Petals 是一个由BigScience工作组开发的大规模预训练语言模型，致力于推动自然语言处理（NLP）领域的边界。这个开源项目旨在提供一个高效、可扩展且可定制化的平台，让开发者和研究人员能够利用大规模数据训练出更智能的语言模型。",
                        imgUrl: "/assets/petals.jpg"
                    },                   
                    {
                        contentTitle:"MLC框架实现LLM的多手机联合推理",
                        contentText:"MLC（Multi-Modal Large-scale Classifier）是由MLC团队开发的一个强大且灵活的多模态预训练模型。该项目的目标是提供一个高效的框架，用于处理各种多模态任务，如图像分类、文本理解及跨模态检索等。通过利用大规模的多模态数据进行预训练，MLC模型能够学习到丰富的跨模态表示，从而在下游任务中展现出卓越性能.",
                        imgUrl: "/assets/1.jpg"
                    }, 
                   {
                        contentTitle:"Nginx代理配置",
                        contentText:"Nginx有反向代理和正向代理,反向代理其实就是反向代理服务器去选择目标服务器获取数据后，在返回给客户端",
                        imgUrl: "/assets/nginx.jpg"
                    },


                    {
                        contentTitle:"Nginx和Apache2打配合",
                        contentText:"Nginx接受用户的请求，作为请求转发服务器，Apache作为后端服务器",
                        imgUrl: "/assets/6.jpg"
                    },
                    {
                        contentTitle:"python后端框架之fastAPI",
                        contentText:"FastAPI是一个用于构建高性能Web应用程序的Python框架。它基于Python 3.7+的新特性，如类型注释、异步I/O和API路由等，提供了一种快速、简单和易于使用的方法来构建Web API",
                        imgUrl: "/assets/7.jpeg"
                    },
                    {
                        contentTitle:"vue炫酷动画制作",
                        contentText:"<Transition> 是vue的一个内置组件，这意味着它在任意别的组件中都可以直接被使用，无需注册。",
                        imgUrl: "/assets/2.jpg"
                    },
                    {
                        contentTitle:"SpringBoot前后端分离",
                        contentText:"前端使用vue和elementUI，后端是springBoot集成redis、mysql。",
                        imgUrl: "/assets/3.jpeg"
                    },
                    {
                        contentTitle:"vue3大秘宝one piece新的内置组件",
                        contentText:"Fragment: Vue 3 组件不再要求有一个唯一的根节点，清除了很多无用的占位 div。Teleport: 允许组件渲染在别的元素内，主要开发弹窗组件的时候特别有用。Suspense: 异步组件，更方便开发有异步请求的组件。",
                        imgUrl: "/assets/4.jpg"
                    },
                ]
            }
        },
        components:{
            contentItem1,
            contentItem2
        }
    }
</script>

<style scoped>
.contentMiddle{
    width: 50vw;
    margin-left: 0.5vw;
  }
  .pos{
    width: 50vw;
    height: 0.00vh;
  }
</style>