<template>
    <div class="tagBox" >
        {{ tagName }}
    </div>
</template>

<script>
    export default {
        name: 'contentRight',
        data(){
            return {
                
            }
        },
        props:{
            tagName: String,
        }

    }
</script>

<style scoped>
    .tagBox{
        min-width: 3vw;
        width: auto;
        height: 2.3vh;
        line-height: 2.3vh;
        border-radius: 0.4vh;
        text-align: center;
        background-color: rgba(255,0,255,0.5);
        color: rgba(255,0,255,1);
        margin-left: 0.3vw;
        margin-top: 0.5vh;
        cursor: pointer;

    }

</style>