<template>
    <div class="content" :style="{zIndex:zidx}">
        <div class="pause"  @click="changeS" v-if="!play"></div>

    <pre>
                <span>多手机联合推理大模型</span>

    这是一个科研项目,研究内容是大模型的多手机联合推理,项目基于最新的MLC框架.项目预期效果是用户可以不再担心设备算力问题,可以直接借助其他用户的手机去进行大模型的推理,前提是其他用户授权同意提供手机计算资源,并且承诺不会侵犯安全隐私问题.目前我们做到的效果是已经能够在一台手机上进行模型的推理了,但是还在攻克大模型的分割问题(这是一个比较棘手的问题).
    </pre>
    </div>
</template>

<script>

export default {
    name: 'content',
        data(){
            return {
                play: true,
            }
        },
        components:{
        },
        methods:{
            changeS(){
                if(!this.play) {
                    this.$refs.v.play();
                    this.play=true;
                }
                else{
                    this.$refs.v.pause();
                    this.play=false;   
                }
            }   
        }
    }
</script>

<style scoped>
span{
    font-size:4vh;
    font-weight:border;
}
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        color: white;
        float: left;
        left: 0;
        top: 0;
        height: auto;
        position:relative;

        video{
            object-fit: contain;
        }
    }
    video {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer; /* 添加光标样式，提示用户可以点击 */
}
.pause{
    position:absolute;
    width: 5vw;
    height:5vw;
    background-image:url("/public/assets/pause.png");
    background-size:contain;
    opacity: 80%;
    left: 21vw;
    top:33vh;
    &:hover{
        width: 6vw;
        height:6vw;
    }
}
</style>