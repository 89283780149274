<template>
    <div>
      <form ref="fileForm" @submit.prevent="uploadAvatar">
        <!-- 自定义按钮 -->
        <button type="button" @click="triggerFileInput">选择文件</button>
        
        <!-- 隐藏默认的文件输入框 -->
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" />
        
        <!-- 可选：提交按钮（如果需要） -->
        <!-- <button type="submit">提交</button> -->
      </form>
      
      <!-- 显示头像预览（可选） -->
      <img v-if="avatarUrl" :src="avatarUrl" alt="User Avatar" />
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        selectedFile: null,
        avatarUrl: '' // 用于显示头像预览
      };
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click(); // 触发隐藏的文件输入框
      },
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.selectedFile = file;
  
          // 使用 FileReader 生成文件的预览 URL
          const reader = new FileReader();
          reader.onloadend = () => {
            this.avatarUrl = reader.result;
          };
          reader.readAsDataURL(file);
  
          // 自动提交表单
          this.$refs.fileForm.submit(); // 提交表单
        }
      },
      async uploadAvatar() {
        if (!this.selectedFile) {
          alert('请选择一个文件');
          return;
        }
  
        const formData = new FormData();
        formData.append('file', this.selectedFile);
  
        try {
          const response = await fetch('/upload-avatar', { // 替换为实际的上传接口
            method: 'POST',
            body: formData
          });
  
          const result = await response.json();
          if (response.ok) {
            alert('头像上传成功');
            this.avatarUrl = result.filePath; // 根据实际接口返回的路径更新头像 URL
          } else {
            alert('头像上传失败');
          }
        } catch (error) {
          console.error('上传错误:', error);
          alert('上传过程中发生错误');
        }
      }
    }
  };
  </script>
  <style scoped>
  /* 自定义按钮的样式 */
  button {
    background-color: #4CAF50; /* 绿色背景 */
    color: white; /* 白色文字 */
    padding: 10px 20px; /* 内边距 */
    border: none; /* 去除边框 */
    border-radius: 5px; /* 圆角 */
    cursor: pointer; /* 鼠标指针 */
  }
  button:hover {
    background-color: #45a049; /* 鼠标悬停时的背景颜色 */
  }
  </style>
  