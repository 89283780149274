import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router';

import contentItem1 from '../components/contentItems/contentItem1';
import contentItem2 from '../components/contentItems/contentItem2';
import home from '../components/home';
import liuyan from '../components/liuyan';
import login from '../components/login';
import fankui from '../components/fankui';
import me from '../components/me';
import study from '../components/study';
import study1 from '../components/study/study1';
import study2 from '../components/study/study2';
import zuoping from '../components/zuoping';
import zuoping1 from '../components/zuoping/zuoping1';
import zuoping2 from '../components/zuoping/zuoping2';
import life from '../components/life';
import canv from "../components/canv.vue";
const routes = [
    { 
      path: '/',
      redirect: '/me'
    }, 
    { 
      path: '/home', 
      name: 'home', 
      component: home,
      children: [
        {
          path: '/contentItem1',
          name: 'contentItem1',
          component: contentItem1
        },
        {
          path: '/contentItem2',
          name: 'contentItem2',
          component: contentItem2
        }        
      ] 
    },
    { 
      path: '/me', 
      name: 'me', 
      component:me,
      children: [
        {
          path: '/contentItem1',
          name: 'contentItem1',
          component: contentItem1
        },
        {
          path: '/contentItem2',
          name: 'contentItem2',
          component: contentItem2
        }        
      ] 
    },
    { 
      path: '/liuyan', 
      name: 'liuyan', 
      component:liuyan 
    },
    { 
      path: '/zuoping', 
      name: 'zuoping', 
      component:zuoping ,
      children: [
        {
          path: '/zuoping1',
          name: 'zuoping1',
          component: zuoping1
        },
        {
          path: '/zuoping2',
          name: 'zuoping2',
          component: zuoping2
        }        
      ] 
    },
    { 
      path: '/study', 
      name: 'study', 
      component:study ,
      children: [
        {
          path: '/study1',
          name: 'study1',
          component: study1
        },
        {
          path: '/study2',
          name: 'study2',
          component: study2
        }        
      ] 
    },
    { 
      path: '/me', 
      name: 'me', 
      component:me 
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/fankui',
      name: 'fankui',
      component: fankui,
    },
    {
      path: '/life',
      name: 'life',
      component: life,
    },
    {
      path: '/canv',
      name: 'canv',
      component: canv,
    },
  ];

  const router = createRouter({
    history: createWebHistory(),//createWebHashHistory(),
    routes,
  });
  
  router.afterEach((to, from) => {
    const container = document.querySelector('.mainContent');
    if (container) {
      container.scrollTop = 0;
    }
  });
  export default router;
