<template>
    <div class="touxiang" :style="{display:yideng,backgroundImage: `url(${durl})`,zIndex:zt}"></div>
    <div class="nav-cont" :style="baccLife">
        
        <router-link class="myName rou" to="/me">
            <div class="xkk">鑫可可
            </div>
        </router-link>
        <div class="blueFire"></div>
        <div class="nav-left">
            <router-link class="la rou" to="/home">
                <div class="navItem">首页</div>
            </router-link>
            <router-link class="la rou" to="/study">
                <div class="navItem">学习区</div>
            </router-link>
            <router-link class="la rou" to="/zuoping">
                <div class="navItem">作品集</div>
            </router-link>
            <router-link class="la rou" to="/liuyan">
                <div class="navItem">留言板</div>
            </router-link>
            <!-- <router-link class="la rou" to="/liuyan">
                <div class="navItem">生活区</div>
            </router-link>
            <router-link class="la rou" to="/liuyan">
                <div class="navItem">游戏区</div>
            </router-link> -->
            <span class="la rou " @click="toSam" >
                <div class="navItem sam">SAMURAI</div>
            </span>
        </div>
        <div class="nav-right">
            <input class="sou" type="text" name="sou" autocomplete="off" style="visibility: hidden;">
            <span class="sou-button" style="visibility: hidden;">搜索</span>
            <span class="la rou" @click="fankuiBegin" ref="k">
                <div class="navItem">反馈</div>
            </span>
            <span class="la rou yh1" @click="dengluS" ref="d" >
                <div class="navItem yh2" :style="dd">登录</div>
            </span>
        </div>
        <div class="sanjiao" :style="fankuiStyle">

        </div>
        <div class="fankui" :style="fankuiStyle" ref="f">
            <textarea class="fankuiContent" v-model="textareaContent"></textarea>
            <div class="bts">
                <div class="reset" @click="clearText">重置</div>
                <div class="send">提交</div>
            </div>
        </div>
        <div class='login' :style="dengluStyle" >
            <div class="form" ref="l">
                <div class="selection">
                    <div class="selectionItem" @click="denglu">登录</div>
                    <div class="selectionItem" @click="zhu">注册</div>
                </div>
                <div class="defaultTouxiang" v-if="!deng" :style="{ backgroundImage: `url(${avatarUrl})` }">
                    <input type="file" ref="fileInput" @change="changeT" style="display: none;"/>                    
                </div>
                <div class="sc" v-if="!deng" @click="handleFileChange">上传头像</div>
                <div class="deng" v-if="deng">
                    <input class="nameUser inpu" name="nameUser" v-model="logname" placeholder="输入帐号名" autocomplete="off">
                    <input class="mima inpu" name="mima" v-model="logp" placeholder="输入密码" autocomplete="off">
                    <div class="wangji">
                        <span class="cuo" :style="{visibility: cvi}">密码错误!</span>
                        <span class="wang">忘记密码?</span>
                    </div>
                </div>
                <div class="zhu" v-else :style="{backgroundColor: ''}">
                    <input :style="{backgroundColor: ''}" class="nameUser inpu" name="nameUser" v-model="userName" placeholder="输入帐号名" autocomplete="off">
                    <input class="mail inpu" name="mail" v-model="email" placeholder="输入邮箱" autocomplete="off">
                    <input class="mima inpu" name="mima" v-model="password" placeholder="输入密码" autocomplete="off">                
                </div>
                <div class="subButton" @click="changeColor" :style="buttuonStyle">
                    {{ submitButton }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>                

import axios from 'axios';
import { mapState, mapActions } from 'vuex';
var ip="introl-whc.cn";//localhost:8000  introl-whc.cn
export default {
        name: 'navigation',
        data(){
            return {
                fankuiStyle:{
                    display: 'none',
                },
                textareaContent:"",
                baccLife: {
                    backgroundColor: "rgba(0,0,0,1)",
                },
                dengluStyle:{
                    display: 'none',
                },
                deng: true,
                submitButton: "登录",
                bacc: 'aquamarine',
                buttuonStyle: {
                        backgroundColor: '',
                },
                userName: "",
                password: "",
                email: "",
                name:"",
                logname:"",
                logp:"",
                cvi:"hidden",//cuowu dis?
                durl:"url(/assets/touxiang.png)",
                selectedFile: null,
                avatarUrl: '/assets/defaultTouxiang.png',// 用于显示头像预览
                yideng: "none",
                zt:0,
                
                wenZi:"加载中...",
                dd:{},
            }
        },
        computed:{

        },
        methods:{
            ...mapActions(['updateUserName', 'updatePassword', 'updateEmail']),
            toSam(){
                window.open(`https://${ip}/samurai`,'_blank');
            },
            async uploadAvatar() {
                if (!this.selectedFile) {
                    alert('请选择一个文件');
                    return;
                }
                const formData = new FormData();
                formData.append('file', this.selectedFile);



                try {
                    const response = await fetch('/', { // 替换为实际的上传接口
                        method: 'POST',
                        body: formData
                    });

                    const result = await response.json();
                    if (response.ok) {
                        alert('头像上传成功');
                        this.avatarUrl = result.filePath; // 根据实际接口返回的路径更新头像 URL
                    } else {
                        alert('头像上传失败');
                    }
                } 
                catch (error) {
                    console.error('上传错误:', error);
                    alert('上传过程中发生错误');
                }
            },
            changeT(e){
                const file = e.target.files[0];
                if (file) {

                    if (!file.type.startsWith('image/')) {
                        alert('请选择一个有效的图片文件');
                        this.$refs.fileInput.value = ''; // 清空文件输入框的值
                        return;
                    }

                    this.selectedFile = file;

                    // 生成文件的预览 URL
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.avatarUrl = reader.result;
                    };
                    reader.readAsDataURL(file);
                    
                }
            },
            handleFileChange(){
                this.$refs.fileInput.click();
            },
            changeMod(){
                if(this.baccLife.backgroundColor!="rgba(0,0,0,0)") this.baccLife.backgroundColor="rgba(0,0,0,0)";
                else this.baccLife.backgroundColor="rgba(0,0,0,0.9)";
            },
            fankuiBegin(){
                if(this.fankuiStyle.display=='none'){
                    this.fankuiStyle.display='block';
                }
                else{
                    this.fankuiStyle.display='none';
                }
            },
            dengluS(){
                this.cvi="hidden";
                if(this.zt>1) return;
                if(this.dengluStyle.display=='none'){
                    this.dengluStyle.display='block';
                }
                else{
                    this.dengluStyle.display='none';
                }
            },
            handleClickOutside(e){
                const f = this.$refs.f;
                const k = this.$refs.k;
                const d=this.$refs.d;
                const l=this.$refs.l;
                if (f&&!f.contains(e.target)&& !k.contains(e.target)) {
                    this.fankuiStyle.display='none';
                }
                if (l&&!l.contains(e.target)&& !d.contains(e.target)) {
                    this.dengluStyle.display='none';
                }            
            },
            clearText(e){
                this.textareaContent="";
            },
            async changeColor(){
                
                this.buttuonStyle.backgroundColor="blue";
                setTimeout(() => {
                    this.buttuonStyle.backgroundColor="";
                }, 100);
                if(this.submitButton=="注册"){
                    if(this.userName.length>=10||this.userName.length<3){
                        alert("名称长度3~10");
                        return;
                    }
                    if(this.password.length>=20||this.userName.length<3){
                        alert("密码长度3~20");return;
                    }
                    const pattern = /^[^@]+@[^@]+\.(cn|com)$/;
                    if( !pattern.test(this.email)){
                        alert('请输入合法的邮箱');return;
                    }
                    const formData = new FormData();
                    formData.append('userName', this.userName);
                    formData.append('email', this.email);
                    formData.append('password', this.password);
                    if(!this.selectedFile){
                        const response = await fetch(`https://${ip}/touxiangs/default.png`);

                        const blob = await response.blob();
                        this.selectedFile = new File([blob], 'default.png', { type: blob.type });
                    }
                    formData.append('file', this.selectedFile);  
                    try {
                        const response = await fetch(`https://${ip}/xinkeke/register`, {
                        method: 'POST',
                        body: formData
                        });
                        const data = await response.json();
                    } catch (error) {
                        alert("注册失败!");
                        console.error('Error:', error);
                    }
                    const newFormData = new FormData();
                    newFormData.append('userName', this.userName);
                    newFormData.append('password', this.password);
                    try {
                        
                        const response = await fetch(`https://${ip}/xinkeke/login`, {
                            method: 'POST',
                            body: newFormData
                        });
                        if(response.ok){
                            this.updateUserName(this.userName);
                            this.updatePassword(this.password);
                        }
                        const data = await response.json();
                        this.durl=`https://${ip}/`+data.touxiangPath;
                        this.zt=4;
                        this.yideng="";
                        this.dd={
                            visibility:'hidden',
                        }
                    } catch (error) {
                        alert("登录失败")
                        console.error('Error:', error);
                    }

                    this.dengluStyle.display='none';

                }
                else if(this.submitButton==="登录"){
                    const formData = new FormData();
                    formData.append('userName', this.logname);
                    formData.append('password', this.logp);
                    try {
                        const response = await fetch(`https://${ip}/xinkeke/login`, {
                            method: 'POST',
                            body: formData
                        });
                        if(response.ok){
                            this.updateUserName(this.logname);
                            this.updatePassword(this.logp);
                            const data = await response.json();
                            this.durl=`https://${ip}/`+data.touxiangPath;
                            this.zt=4;
                            this.yideng="";
                            this.dd={
                                visibility:'hidden',
                            }
                        }
                        else{
                            alert("登录失败！")
                            this.cvi="";
                            return;
                        }
                        
                    } catch (error) {
                        console.error('Error:', error);
                    }
                    this.dengluStyle.display='none';

                }

            },
            denglu(){
                this.deng=true;
               this.submitButton="登录";
             
            },
            zhu(){
                this.deng=false;
                this.submitButton="注册";
            }
        },
        mounted(){
            document.addEventListener("click",this.handleClickOutside);
        },
    }
</script>

<style scoped>
    @font-face {
        font-family: 'myFont1';
        src: url(/public/assets/myFont1.ttf) format('truetype');
    }
    .touxiang{
        position: fixed;
            margin-top: 0.5vh;
            width:3.5vw;
            height: 3.5vw;
            border-radius: 5vw;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            right: 1vw;
            z-index: 1;
        }
    .xkk{
        display: inline-block;
        height: 5vh;
        width:auto;
            background: linear-gradient(45deg, red, yellow);
            -webkit-background-clip: text;
            color: transparent;            
        
    }
    .blueFire{
        display:inline-block;
        background-image:url("/public/assets/blueFire.gif");
        width: 5vh;
        height:10vh;
        background-size:contain;
        background-repeat:no-repeat;
        margin-left: -10vw;

    }
    .sanjiao{
        width: 0;
        height: 0;
        position: absolute;
        right: 9vw;
        top: 5vh;
        z-index: 21;
        border-top: 0vh solid transparent;
        border-right: 1vw solid transparent;        
        border-bottom: 2.4vh solid rgba(255, 255, 255, 1);
        border-left: 1vw solid transparent;

    }
    .fankui{
        width: 15vw;
        height: 18vh;
        background-color: rgba(255, 255, 255, 1);
        color: black;
        position: fixed;
        right: 1vw;
        top: 7.3vh;
        z-index: 20;
        border-radius: 2vh;
        .fankuiContent{
            all:unset;
            width:13vw;
            height:10vh;
            margin-top: 2vh;
            margin-left: 0.5vw;
            border: 1px solid black;
            border-radius: 1vh;
            padding: 0.5vh 0.5vw;
            font-size: 1.5vh; 
            font-family: initial;
            overflow-y: scroll;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        .fankuiContent::-webkit-scrollbar {
            width: 0.2vw; 
            background-color: #f5f5f5;   
            display: none;
        }
        .fankuiContent::-webkit-scrollbar-track {
            background-color: #f5f5f5; 
        }
        .fankuiContent::-webkit-scrollbar-thumb {
            background-color: #888; 
            border-radius: 5vh; 
        }
        .fankuiContent::-webkit-scrollbar-button {
            display: none; 
        }
        .bts{
            width:13vw;
            margin-left: 1vw;
            margin-top: 0.5vh;
            display: flex;
            justify-content: space-between;
            .reset, .send{
                height: 3vh;
                width: 5vw;
                margin-left: 1vw;
                font-size: 2vh;
                text-align: center;
                line-height: 3vh;
                cursor: pointer;
            }
            .send:hover,.reset:hover{
                color: blue;
            }
            .send{
                margin-right: 1vw;
            }
        }
    }
    .rou{
        text-decoration: none; /* 移除下划线 */
        color: inherit; /* 保持文字颜色 */
    }
    .sou{
        width: 0vw; 
        height: 1.2vh; 
        border: 0.3px solid rgb(236, 228, 228);
        border-radius: 0.5vh;
        background-color: rgba(255, 255, 255, 0);
        padding: 0.5vh;
        font-family:'Courier New', Courier, monospace;
        font-size: 1.5vh;
        font-weight: lighter;
        margin-right: 0;
        vertical-align: top;
        color: white;
        padding-left: 0.5vw;
    }
    .sou-button{     
        font-size: 2.5vh;
        margin-left: 0.51vw;
    }
    .sou:focus {
        outline: none; /* 去除默认的聚焦边框 */
    }
    .sou:not(:placeholder-shown) {
        font-weight: bold; /* 内容输入后的字体加粗 */
    }
    .nav-left{
        display: inline-block;
        margin-left: 7vw;
        margin-top: 2vh;
    }
    .myName{
        margin-left: 2vw ;
        margin-top: 1vh;
        font-size: 4vh;
        display: inline-block;
    }
    .nav-right{
        display: inline-block;
        margin-left: 7vw;
        font-size: 0;        
        height: 3vh;
        line-height: 3vh;
        margin-top: 2vh;
    }
    .nav-cont{
        position: fixed;z-index: 2;
        top: 0;
        left:0;
        color: rgba(255, 255, 255, 1);
        height: 6vh;
        width: 100vw;
        background-color: rgba(0,0,0,1); 
        font-family: 'myFont1'; 
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .la{
        font-size: 2.5vh;
        margin-left: 2vw;
        cursor: pointer;


    }     
    .navItem{
        display: inline-block;
        width: 5vw;
        height: 3vh;
        line-height: 3vh;
        text-align: center;
        vertical-align:middle;
        &:hover{
            font-size: 3vh;
            background: linear-gradient(45deg, red, yellow);
            -webkit-background-clip: text;
            color: transparent;            
        }
    }
    .sam{
        width: 6vw;
    }
    .nav-left,.myName,.nav-right{
        height: 5vh;
    }   


    .subButton{
        width:21vw;
        height: 5vh;
        margin-top: 7vh;
        text-align: center;
        line-height: 5vh;
        font-size: 3vh;
        border-radius: 2vh;
        border: 0.1vh solid;
        
    }

    .login{
        width: 100vw;
        height: 94vh;
        position: fixed;
        right:0;
        top:6vh;
        z-index: 10;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form{
        width:30vw;
        height: 70vh;
        background-color: white;
        border-radius: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        margin-top: 6vh;
        margin-left: 35vw;
        color: black;
        font:initial;
        .defaultTouxiang{
            margin-top: 0vh;
            width:7vw;
            height: 7vw;
            border-radius: 5vw;
            background-image: url(../../public/assets/defaultTouxiang.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .sc{
            all: unset;
            cursor: pointer;
            width: 30vw;
            text-align: center;
            color: black;
            margin-top: 1vh;
            font-size: 2vh;
            &:hover{
                color: red;
            }
        }
        .zhu{
            width: 20vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }.deng{
            width: 20vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .inpu{
        all: unset;
        width:20vw;
        height: 5vh;
        margin-top: 3vh;
        border-width: 0.3vh;
        border-bottom-style: solid;
        padding-left: 0.5vw;      
        font-size: 2vh;
    }
    .selection{
        width:30vw;
        display: flex;
        justify-content: center;
        margin-top: 5vh;

        .selectionItem{
            width: 4vw;
            height: 4vh;
            cursor: pointer;
            margin-left: 3vw;
            margin-right: 3vw;
            font-family: 'myFont1';
            font-size: 3.5vh;
            text-align: center;
            line-height: 4vh;
        }
    }    
    .wangji{
        width: 20vw;
        height: 7vh;
        margin-top: 1vh;
        color: blue;
        font-size: 1.5vh;
        display: flex;
        justify-content: space-between;
        .cuo{
            color: red;
        }
        .wang{
            cursor: pointer;
        }
    }

</style>