<template>
    <div class="contentLeft">
        <div class="calendar">
            <div class="rili"><span>日历</span></div>
            <div class="selection">
                <div class="sel selection-year">
                        <div class="sub sub-year">{{ "<<" }}</div>
                        <span class="date-content date-year">{{date.getFullYear()}}年</span>
                        <div class="add add-year">{{ ">>" }}</div>
                </div>
                <div class="sel selection-month">
                        <div class="sub sub-month">{{ "<<" }}</div>
                        <span class="date-content date-month">{{date.getMonth()}}月</span>
                        <div class="add add-month">{{ ">>" }}</div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th v-for="(value, index) in weekdays" :key="zhou+index">{{value}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, rowIndex) in [1,2,3,4,5,6,7]" :key="'row-'+rowIndex">
                        <td v-for="(value, index) in daysArray.slice((row-1)*7,row*7)" :key='date'>{{ value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="welcome">
            <span>欢迎</span>
            <span class="welcome-content">这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容</span>
        </div>     
        <div class="resou">
            <div class="blockTitle">热搜</div>
            <div class="resouContainer">
                <div class="resouItem" v-for="(value, index) in resous" :key="index.toString+value">
                    {{ index }}. {{value}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'contentLeft',
        data(){
            return {
                weekdays: ['一', '二', '三', '四', '五', '六', '日'],
                date: new Date(),
                month30: [4,6,9,11],
                month31: [1,3,5,7,8,10,12],
                resous:[
                    "热搜1",
                    "热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1",
                    "热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1",
                    "热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1",
                    "热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1",
                    "热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1热搜1",
                ]
            }
        },
        computed:{
            daysArray(v){
                var res=new Array();
                for(let i=1;i<=28;i++) res.push(i);
                if(this.month30.indexOf(this.date.getMonth()+1)>=0){
                    res.push(29);
                    res.push(30);
                }
                else if(this.month31.indexOf(this.date.getMonth()+1)>=0){
                    res.push(29);
                    res.push(30);
                    res.push(31);                }
                else{
                    if(this.date.getFullYear%4==0) return res;
                    res.push(29);
                }
                return res;
            }
        }
    }
</script>

<style scoped>
    .contentPos{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;

        width: 15vw;
        height: 94vh;
        margin-left: 8vw;
        border-radius: 3vh;
        
    }
    .resouContainer{
        height: 32vh;
        overflow-y: scroll;
        border-radius: 1vw;
    }
    .resouContainer::-webkit-scrollbar{
        height: 0;
        width: 0;
    }
    .blockTitle{
        font-size: 3vh;
    }
    .contentLeft{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;

        background-color: rgba(0, 255, 255, 0.8);
        width: 15vw;
        height: 94vh;
        border-radius: 3vh;
        margin-left: 8vw;
        

        .calendar{
            width: 15vw;
            font-size: 1.5vh;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-content: flex-start;
            margin-top: 2vh;
            height: 33vh;
            tr{
                height: 4vh;
            }           
            .rili{
                font-size: 3vh;
            }
            .selection{
                width: 15vw;
                display: flex;
                justify-content: space-evenly;
                font-size: 2vh;
                .date-content{
                    margin-left: 0.3vw;
                }
                .selection-year{
                    width:7vw;
                    margin-left: 1vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                span{
                    display: inline-block;
                }
                .selection-month{
                    width: 7vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .add{
                    cursor: pointer;
                    display: inline-block;
                    font-size: 2vh;
                    margin-left: 0.3vw;
                }
                .sub{
                    cursor: pointer;
                    display: inline-block;
                    font-size: 2vh;

                }
            }
        }
        .resou{
            display: flex;
            flex-wrap: wrap;
            .blockTitle{
                width: 15vw;
                text-align: center;
                margin-top: 2vh;
            }
            .resouItem{
                font-size: 2vh;
                border-width: 0.1vw;
                border-style: solid;
                border-color: black;
                margin-top: 1vh;
                margin-left: 0.51vw;
                width: 13vw;
                border-radius: 0.3vw;
                background-color: antiquewhite;
                padding: 0.5vw;
                cursor: pointer;
            }
        }

    }    
    .welcome{
        display: flex;
        align-content: flex-start;
        margin-top: 0.5vh;
        font-size: 3vh;
        flex-wrap: wrap;
        justify-content: center;
        .welcome-content{
            width: 13vw;
            font-size: 2vh;
            font-size:lighter;
            display: block;
            text-indent: 2vw;
            margin-top: 1vh;
        }

    }
    table {
        width: 90%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #ddd; /* 设置每个单元格的边框样式 */
        text-align: center;
    }
    .lineH{
        height: 0.5vh;
        width: 12vw;
        background-color: black;
        margin-top: 2vh;
    }
    .lineV{
        height: 4vh;
        width: 0.1vw;
        display: inline-block;
        background-color: black;
        margin-left: 0.3vw;
    }

    .sel{
        height: 3vh;
        line-height: 3vh;
    }
</style>