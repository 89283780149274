<template>
    <liuyan/>
</template>

<script>
    import contentItems from './contentItems';
    import liuyan from './liuyan';

    export default {
        name: 'contentLeft',
        data(){
            return {
                items:[
                    {
                        contentTitle:"这是公告内容这是公告内容这是公告内容这是公告内容这是公告",
                        contentText:"这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容",
                        imgUrl: "/public/assets/back.png"
                    }
                ]
            }
        },
        components:{
            contentItems,
            liuyan
        }
    }
</script>

<style scoped>
   

</style>