<template>
    <canv/>
    <div class="homeC" ref="hmc" @scroll="handleScroll">
        <topB/>
        <contentLeftFixed :fixed="fd"/>

        <contentItems/>

        <contentRightFixed :fixed="fd"/>
            <div class="foot">
            <a class="a2" href="https://beian.miit.gov.cn/" target="_blank">黔ICP备2024032689号-1</a>
            <img class="im" src="/assets/beian.png">
        <a class="a1" href="https://beian.mps.gov.cn/#/query/webSearch?code=52050002000116" rel="noreferrer" target="_blank">贵公网安备52050002000116</a>     
            </div>
    </div>

</template>

<script>
    import contentItems from './contentItems';
    import contentRightFixed from './contentRightFixed';
    import contentLeftFixed from './contentLeftFixed';
    import canv from "./canv.vue";
    import topB from "./topB.vue";
import TopB from './topB.vue';

    export default {
        name: 'contentLeft',
        data(){
            return {
                items:[
                    {
                        contentTitle:"这是公告内容这是公告内容这是公告内容这是公告内容这是公告",
                        contentText:"这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容这是公告内容",
                        imgUrl: "/src/assets/back.png"
                    }
                ],
                fd:'false',
            }
        },
        methods:{
            handleScroll(){
                if((this.$refs.hmc.scrollTop)>=(window.innerHeight)*0.22){
                    this.fd='true';
                }
                else this.fd="";
                console.log(this.fd,(this.$refs.hmc.scrollTop));
            }
        },
        computed:{

        },
        components:{
            contentItems,
            contentLeftFixed,
            contentRightFixed,
            canv,topB
        },
        beforeRouteEnter (to, from, next) {
                next(vm => {
                window.scrollTo(0, 0);
            });
        }
    }
</script>

<style scoped>

    .pos{
        width: 100vw;
        height: 6vh;
        background-color: red;
    }
    .footTop{
        width: 100vw;
        height: 5vh;
    }
    .foot{
        width: 100vw;
        height: 16vh;
        background-color: rgba(0,0,0,0);
        z-index: 100;
        display:flex;
        justify-content: center;
        line-height: 6vh;
        vertical-align: bottom;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 18vh;
        .im{
            display: block;
            margin-right: 1vw;
        }
        .a1,.a2{
            display: inline-block;
            height: 3vh;
            text-align: center;
            line-height: 3vh;
            font-size: 2vh;
            color: #ffa200;
            margin-left: 2vw;
        }
        .a2{
            width: 100vw;   
            margin-left: 1vw;
            margin-top: 1vh;
        }
        .a1{
            margin-left: 0vw;
            margin-top: 1vh;
        }
    }        
    .homeC{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        margin-top: 6vh;
    }
    .homeC::-webkit-scrollbar {
        width: 0.3vw; 
        background-color: #f5f5f5;   
    }
    .homeC::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .homeC::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5vh; 
    }
    .homeC::-webkit-scrollbar-button {
        display: none; 
    }
</style>