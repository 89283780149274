<template>
    <div class="life">
        <donghua/>
    </div>
    
</template>

<script>
import contentRight from './contentRight';
import contentLeft from './contentLeft';
import contentItems from './contentItems';
import donghua from './donghua'
export default {
    name: 'me',
    data(){
        return {
            
        }
    },
    components:{
        contentLeft,
        contentRight,
        contentItems,
        donghua,
    }
}
</script>

<style scoped>

.life{
    font-size: 10vh;
    width: 100vw;
    height: 100vh;
    background-color: black;
}
</style>