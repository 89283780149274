<template>
<div class="content" :style="{zIndex:zidx}">
    <div class="pause" @click="changeS" v-if="!play"></div>
    <pre>
                        <span>SAMURAI</span>

演示视频:
<video  src="/samurai.mp4" @click="changeS" ref='v'></video>


    这款游戏名为Samurai,是我和室友总共三个人一起开发的.开发的初衷主要是想做一个类似只狼一样的3D魂系游戏,其中的大量灵感均来自只狼,包括UI界面,打击动作,画面风格,音效等等我们都尽量往只狼看齐.
    实现的效果包括游戏加载界面，用户交互界面，真实环境渲染，游戏音效，人物模型以及动画等等。玩家可以直接控制角色的走动，跑动，冲刺，跳跃，攻击，防御，锁定敌人视角，以及长按攻击健进行蓄力攻击，按shift键进行垫步等等。而敌人可以根据玩家位置，以及玩家的输入，去攻击玩家，或者进行防御。除此之外，还有冲刺消耗耐力条，被攻击减少血条等功能。
    开发使用的脚本语言为C#,使用的游戏引擎为Unity3D.游戏中我主要负责的是场景搭建以及用户交互部分。场景主要分为两个部分，第一个是正式进入游戏前的场景,第二个就是游戏中的场景。第一个场景包括一系列的界面，选项以及淡入淡出的切换。用户在第一个场景可以选择进入游戏,开启新游戏,退出游戏等等。第二个场景包括场景模型的引入使用，血条，装备，设置界面，音效等等，同时我们会根据用户的操作或者受到的攻击去减少血条或者架势条,最后，用户还可以在设置界面进行一系列的操作选择,并且此时游戏内容暂停. 
</pre>
</div>
</template>

<script>
    // 这款游戏名为Samurai,是我和室友总共三个人一起开发的.开发的初衷主要是想做一个类似只狼一样的3D魂系游戏,其中的大量灵感均来自只狼,包括UI界面,打击动作,画面风格,音效等等我们都尽量往只狼看齐.
    // 开发使用的脚本语言为C#,使用的游戏引擎为Unity.其中人物模型,场景模型,动画我们目前没法做得很出色,只能在网上搜索购买的素材,渠道包括各大模型动画网站,以及淘宝等购物平台.除此之外,所有的图片资源,音频资源等等都是由我通过PS,PR等软件处理制作.
    // 游戏中我负责的部分主要是用户交互部分和美术部分.美术部分包括第一个场景(即进入正式进入游戏,控制主角之前的场景)的制作,以及进入游戏后装备,血条,设置界面等.用户交互部分包括用户在第一个场景可以选择进入游戏,开启新游戏,退出游戏等等,在第二个场景可以根据用户的操作,受到的攻击等减少血条或者架势条,以及用户可以在设置界面进行一系列的操作选择,并且此时游戏内容暂停.
    
export default {
        name: 'content',
        data(){
            return {
                play: false,
            }
        },
        components:{
        },
        methods:{
            changeS(){
                if(!this.play) {
                    this.$refs.v.play();
                    this.play=true;
                }
                else{
                    this.$refs.v.pause();
                    this.play=false;   
                }
            }   
        }
    }
</script>

<style scoped>

span{
    font-size:4vh;
    font-weight:border;
}
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        color: white;
        float: left;
        left: 0;
        top: 0;
        height: auto;
        position:relative;
        video{
            object-fit: contain;
        }
    }
    video {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer; /* 添加光标样式，提示用户可以点击 */
}
.pause{
    position:absolute;
    width: 5vw;
    height:5vw;
    background-image:url("/public/assets/pause.png");
    background-size:contain;
    opacity: 80%;
    left: 21vw;
    top:34vh;
    &:hover{
        width: 6vw;
        height:6vw;
    }
}    

</style>