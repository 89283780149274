<template>
    <div class="contentItem">
        <div class="img" :style="{backgroundImage: `url(${imgUrl})`}"></div>
        <div class="content" sec={{imgUrl}}>
            <div class="itemTitle">{{ contentTitle }}</div>  
            <div class="text">{{ contentText }}</div>
            <div class="itemTags">

            </div>     
            <div class="itemInform">

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'contentItem',
        data(){
            return {
                maxWidth: 50,

            }
        },
        props:{
            imgUrl:{
                type: String,
                required: true
            },
            contentTitle:{
                type: String,
                required: true
            },
            contentText:{
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
    .contentItem{
        width: 50vw;
        height: 25vh;
        border-radius: 3vh; 
        position: relative;
        border: 2px solid gray;
        border-radius: 2vh;
        margin-top: 1vh;
        &:hover{
            border-color: purple;
        }
        .img{
            width:27.5vh;
            height: 21vh;
            border-radius: 1vh;
            position: absolute;
            left: 1.5vw;
            top: 1.8vh;
            z-index: 1;
            cursor: pointer;
            background-size: cover;
            &:hover{

                background-size:200%;
            }
        }

        .content{
            position: absolute;
            left: 15.8vw;
            top: 2.3vh;
            height: 20vh;
            width: 33.9vw;
            z-index: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: space-between;
            .itemTitle{
                width: 34vw;
                height: 5vh;
                line-height: 5vh;
                font-size: 3vh;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-wrap: break-word;
                cursor: pointer;
            }
            .text{
                width: 34vw;
                height: 3.5vh; 
                font-size: 2.5vh;
                text-overflow: ellipsis;
                margin-top: 1vh;
                overflow: hidden;
                white-space: nowrap;
                font-weight: lighter;
                color: rgb(102, 102, 102);
            }
            .itemTags{
                width: 34vw;
                height: 5vh;
                margin-top: 5vh;
            }
            .itemInform{
                width: 34vw;
                height: 5vh;
            }
        }
    }
</style>