<template>
    <div class="content" :style="{zIndex:zidx}">
        <div class="pause"  @click="changeS" v-if="!play"></div>

    <pre>
                            <span>TDN</span>

    项目目前还在着手中,主要是搭建一个技术知识文章分享平台,用户可以在该平台上分享任何技术知识,不再仅仅局限于计算机知识,可以是任何专业知识,任何生活知识,任何技能知识.呈现形式主要是移动端APP.
    </pre>
    </div>
</template>

<script>

export default {
    name: 'content',
        data(){
            return {
                play: true,
            }
        },
        components:{
        },
        methods:{
            changeS(){
                if(!this.play) {
                    this.$refs.v.play();
                    this.play=true;
                }
                else{
                    this.$refs.v.pause();
                    this.play=false;   
                }
            }   
        }
    }
</script>

<style scoped>
span{
    font-size:4vh;
    font-weight:border;
}
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        color: white;
        float: left;
        left: 0;
        top: 0;
        height: auto;
        position:relative;

        video{
            object-fit: contain;
        }
    }
    video {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer; /* 添加光标样式，提示用户可以点击 */
}
.pause{
    position:absolute;
    width: 5vw;
    height:5vw;
    background-image:url("/public/assets/pause.png");
    background-size:contain;
    opacity: 80%;
    left: 21vw;
    top:33vh;
    &:hover{
        width: 6vw;
        height:6vw;
    }
}
</style>