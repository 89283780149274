<template>
    <div class="content" :style="{zIndex:zidx}">
        <div class="pause"  @click="changeS" v-if="!play"></div>

    <pre>
                <span>OpenGL无引擎渲染场景</span>

演示视频:
<video  src="/opengl.mp4" @click="changeS" ref='v'></video>


    不借助任何引擎,只使用OpenGL搭建3D虚拟下雪场景,用户可以在场景中进行移动,视角旋转,zoom-in,zoom-out.这个项目完全由我一个人开发,使用的语言为C++.
    项目主要分为用户交互部分,场景渲染部分.用户交互部分包括用户按下W, S, A, D或者上下左右箭头实现各个方向的移动,以及滚动鼠标滚轮,或者移动鼠标实现视角的各个方向旋转,或者zoom-in,zoom-out.场景部分主要包括天空盒和地面的制作,以及雪的制作.天空盒的制作我使用的是立方体天空盒,天空盒的纹理是我使用ps制作的,场景渲染主要使用的是顶点着色器和片段着色器.着色器的使用过程是先编写完成着色器处理坐标以及颜色的代码,然后再在主程序中创建一系列做色器,并使用这些着色器去编译并绑定之前编写的着色器代码,最后连接编译过的一系列着色器就可以生成一个着色程序去渲染场景了.
    着色程序的使用需要顶点数组对象以及纹理对象.顶点数组对象的创建过程需要先生成顶点数组,和缓存对象,然后将他们绑定在一起并根据着色程序设置某些复杂的属性就可以使用了.纹理对象可以直接先生成并调节需要的参数,然后使用stbi进行纹理图像的加载绑定就可以使用了.有了顶点数组对象,纹理对象和着色程序我们就可以直接进行着色渲染了.
    然后用户交互部分我是写了一个类去控制的,这个类的成员变量主要有位置参数,视野宽高参数,视线角度参数,移动速度参数等等,我们直接调用Opengl的API得到用户输入,然后通过平移,缩放,旋转等手段实现用户的移动以及视角转变,另外,我们还需要根据坐标信息进行碰撞检测.
   </pre>
    </div>
</template>

<script>

export default {
    name: 'content',
        data(){
            return {
                play: false,
            }
        },
        components:{
        },
        methods:{
            changeS(){
                if(!this.play) {
                    this.$refs.v.play();
                    this.play=true;
                }
                else{
                    this.$refs.v.pause();
                    this.play=false;   
                }
            }   
        }
    }
</script>

<style scoped>
span{
    font-size:4vh;
    font-weight:border;
}
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        color: white;
        float: left;
        left: 0;
        top: 0;
        height: auto;
        position:relative;

        video{
            object-fit: contain;
        }
    }
    video {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer; /* 添加光标样式，提示用户可以点击 */
}
.pause{
    position:absolute;
    width: 5vw;
    height:5vw;
    background-image:url("/public/assets/pause.png");
    background-size:contain;
    opacity: 80%;
    left: 21vw;
    top:34vh;
    &:hover{
        width: 6vw;
        height:6vw;
    }
}
</style>