<template>
    <div class="content" :style="{zIndex:zidx}">
    <pre>
第二章 JavaScript高级语法
2.1、Exception
2.1.1、异常概述
在ES3之前JavaScript代码执行的过程中，一旦出现错误，整个JavaScript代码都会停止执行，这样就显的代码非常的不健壮。

在Java或C#等一些高级语言中，都提供了异常处理机制，可以处理出现的异常，而不会停止整个应用程序。

从ES3开始，JavaScript也提供了类似的异常处理机制，从而让JavaScript代码变的更健壮，即使执行的过程中出现了异常，也可以让程序具有了一部分的异常恢复能力。

当错误发生时，JavaScript 提供了错误信息的内置 error 对象。

error 对象提供两个有用的属性：name 和 message 。

Error 对象属性
<img src="/assets/jie4.png">
Error Name Values

error 的 name 属性可返回六个不同的值：
<img src="/assets/jie5.png">
2.1.2、异常捕捉
ES3开始引入了 try-catch 语句，是 JavaScript 中处理异常的标准方式。

语法格式：
<img src="/assets/jie6.png">
在 try…catch 中，try 中一旦出现错误则其它语句不能执行，如果不出现错误则 catch 中的语句不会执行。

Javascript 参考其它编程语言，也提供了一种 finally 语句：不管 try 中的语句有没有错误，在最后都会执行 finally 中的语句。也就是说，try 中语句不发生错误执行完毕后会执行 finally 中的语句，try 中的语句发生错误，则执行 catch中的语句，catch 中的语句执行完毕后也会执行 finally 中的语句。

案例演示：
<img src="/assets/jie7.png">
在JavaScript中，如果添加了 finally 语句，则 catch 语句可以省略。但是如果没有 catch 语句，则一旦发生错误就无法捕获这个错误，所以在执行完 finally 中的语句后，程序就会立即停止了。所以，在实际使用中，最好一直带着 catch 语句。如果你写了 catch 语句，则finally 语句也是可以省略的。

2.1.3、异常演示
2.1.3.1、Eval 错误
EvalError 指示 eval() 函数中的错误。更新版本的 JavaScript 不会抛出任何 EvalError，请使用 SyntaxError 代替。

案例演示：
<img src="/assets/jie8.png">
2.1.3.2、范围错误
RangeError 会在您使用了合法值的范围之外的数字时抛出。

案例演示：您不能将数字的有效位数设置为 500。
<img src="/assets/jie9.png">
2.1.3.3、引用错误
假如您使用（引用）了尚未声明的变量，则 ReferenceError 会被抛出：

案例演示：
<img src="/assets/jie10.png">
2.1.3.4、语法错误
假如您计算带语法错误的代码，会 SyntaxError 被抛出：

案例演示：
<img src="/assets/jie11.png">
2.1.3.5、类型错误
假如您使用的值不在期望值的范围之内，则 TypeError 被抛出：

案例演示：
<img src="/assets/jie12.png">
2.1.3.6、URI 错误
假如您在 URI 函数中使用非法字符，则 URIError 被抛出：

案例演示：
<img src="/assets/jie13.png">
2.1.4、异常抛出
在大部分的代码执行过程中，都是出现错误的时候，由浏览器(javascript引擎)抛出异常，然后程序或者停止执行或被try…catch 捕获。

然而有时候我们在检测到一些不合理的情况发生的时候也可以主动抛出错误，请使用 throw 关键字抛出来主动抛出异常。

注意事项：

thow后面就是我们要抛出的异常对象，在以前的时候都是出现错误的时候浏览器抛出异常对象，只是现在是我们自己主动抛出的异常对象。
只要有异常对象抛出，不管是浏览器抛出的，还是代码主动抛出，都会让程序停止执行。如果想让程序继续执行，则有也可以用try…catch来捕获。
每一个错误类型都可以传入一个参数，表示实际的错误信息。
我们可以在适当的时候抛出任何我们想抛出的异常类型。throw new SyntaxError("语法错误...");
2.1.4.1、主动抛出内置异常
<img src="/assets/jie14.png">
2.1.4.2、主动抛出自定义异常
我们不仅仅可以抛出js内置的错误类型的对象，也可以自定义错误类型，然后抛出自定义错误类型的对象。

如果要自定义错误类型，只需要继承任何一个自定义错误类型都可以，一般直接继承Error即可。
<img src="/assets/jie15.png">

    </pre>
    </div>
</template>

<script>

export default {
        name: 'content',
        data(){
            return {
                pdfUrl: '/assets/HTML4.pdf'
            }
        },
        components:{
        },
        props:{
            zidx:Number
        }
    }
</script>

<style scoped>
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
img{
    width: 100%;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        float: left;
        left: 0;
        top: 0;
        height: auto;
    }
</style>