<template>
    <canv/>
    <div class="liuyanC">
        <contentLeftFixed fixed="true"/>
        <div class="contentMiddle">
            <div class="submit">
                <textarea class="liuyanContent" type="textarea" name="liuyanContent" :style="liuyanContentStyle" v-model="textCnt"></textarea>
                <span class="liuyanSubmit" @click="inputBegin">{{ buttonContent }}</span>            
            </div>
            
            <liuyan1 v-for="(v,i) in items" :liuyanId="v.liuyanId" :name="v.name" :touxiangPath="v.touxiangPath" :time="v.time" :content="v.content"/>
            
        </div>
        <contentRightFixed fixed="true"/>
        <div class="liuPos"></div> 

            <div class="foot">
            <a class="a2" href="https://beian.miit.gov.cn/" target="_blank">黔ICP备2024032689号-1</a>
            <img class="im" src="/assets/beian.png">
            <a class="a1" href="https://beian.mps.gov.cn/#/query/webSearch?code=52050002000116" rel="noreferrer" target="_blank">贵公网安备52050002000116</a>     
            </div>
    </div>
</template>

<script>
    import liuyan1 from './liuyanItems/liuyan1.vue'
    import contentRightFixed from './contentRightFixed';
    import contentLeftFixed from './contentLeftFixed';
    import canv from "./canv.vue"
    import { mapState } from 'vuex';
    import axios from 'axios';
    import topB from "./topB.vue";

    var ip="introl-whc.cn";//localhost:8000  introl-whc.cn
    export default {
        name: 'liuyan',
        data(){
            return {
                buttonContent: "我也要留言",
                textCnt: "",
                liuyanContentStyle:"",
                items:[
                ],
                
            }
        },
        components:{
            liuyan1,
            contentLeftFixed,
            contentRightFixed,
            canv,topB,
        },
        computed:{
            ...mapState(['userName', 'password','email']),
        },
        async mounted(){
            let res=await axios.get(`https://${ip}/xinkeke/liuyanAll`, {
            });      
            // res=await axios.get(`https://${ip}/xinkeke/liuyanAll`, {
            // });
            res.data.forEach(element => {
                let newObj=new Object();
                newObj.liuyanId=element.liuyanId;
                newObj.content=element.content;
                newObj.name=element.username;
                newObj.time=element.created_at.replace('T'," ");
                newObj.touxiangPath=`https://${ip}/${element.touxiangPath}`;
                this.items.push(newObj);
            });
        },
        methods:{
            judge(){
            },
            async inputBegin(){
                if(this.buttonContent=="我也要留言") {
                    this.buttonContent="提交";

                }
                else {
                    // formData=new FormData();
                    // formData.append('content', this.textCnt);
                    // formData.append('username',this.userName);
                    // const response = await fetch('/liuyan', { // 替换为实际的上传接口
                    //     method: 'POST',
                    //     body: formData
                    // });
                    if(this.userName==''){
                        alert("请先登录!");
                        return;
                    }
                    if(this.textCnt.length<=1){
                        alert('请正确留言！');
                        return;
                    }
                    await axios.post(`https://${ip}/xinkeke/liuyan`, {
                        content: this.textCnt,
                        username: this.userName,
                        touxiangPath:'',
                        created_at: '',
                        liuyanId: 0,
                    });

                    
                    this.textCnt="";
                    this.buttonContent="我也要留言";
                }
                if(this.liuyanContentStyle===""){
                    this.liuyanContentStyle="padding-right: 1vw;padding-bottom: 2vh; border: 1px;padding-left: 1vw;padding-top: 2vh;border: 1.5px hidden black;height:10vh;";
                }
                else this.liuyanContentStyle="";


                const res=await axios.get(`https://${ip}/xinkeke/liuyanAll`, {
                });      
                this.items.length=0; 
                res.data.forEach(element => {                  
                    let newObj=new Object();
                    newObj.liuyanId=element.liuyanId;
                    newObj.content=element.content;
                    newObj.name=element.username;
                    newObj.time=element.created_at.replace('T'," ");
                    newObj.touxiangPath=`https://${ip}/${element.touxiangPath}`;
                    this.items.push(newObj);
                    
                });
            }
        
        }
    }
</script>

<style scoped>
.liuPos{
        width: 100vw;
        height: 20vh;
            
}
    .submit{
        width:50vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 1vh;
        border: 2px solid gray;
        border-radius: 2vh;
        &:hover{
            border-color: purple;
        }
        .liuyanContent{
            all: unset;
            width:44vw;
            height: 0vh;
            /* padding-right: 1vw;
            padding-bottom: 2vh; 
            border: 1px;
            padding-left: 1vw;
            padding-top: 2vh; 
            border: 10px solid black;*/
            border-radius: 1vh;
            font-size: 2vh;
            transition: 0.5s linear;
        }
        textarea::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .liuyanSubmit{
            width: 15vw;
            height: 5vh;
            cursor: pointer;
            line-height: 5vh;
            text-align: center;
            text-decoration: underline;
            font-family: 'myFont1';
            font-size: 3.5vh;
            font-weight: lighter;
        }
    }

   .contentMiddle{
    width: 50vw;
    margin-left: 0.5vw;
    height: 140vh;
    border-radius: 3vh;

  }
  .liuyanC{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        margin-top: 6vh;
    }
    .liuyanC::-webkit-scrollbar {
        width: 0.3vw; 
        background-color: #f5f5f5;   
    }
    .liuyanC::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .liuyanC::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5vh; 
    }
    .liuyanC::-webkit-scrollbar-button {
        display: none; 
    }
    .foot{
        width: 100vw;
        height: 16vh;
        background-color: rgba(0,0,0,0);
        z-index: 100;
        display:flex;
        justify-content: center;
        line-height: 6vh;
        vertical-align: bottom;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 18vh;
        .im{
            display: block;
            margin-right: 1vw;
        }
        .a1,.a2{
            display: inline-block;
            height: 3vh;
            text-align: center;
            line-height: 3vh;
            font-size: 2vh;
            color: #ffa200;
            margin-left: 2vw;
        }
        .a2{
            width: 100vw;   
            margin-left: 1vw;
            margin-top: 1vh;
        }
        .a1{
            margin-left: 0vw;
            margin-top: 1vh;
        }
    }        
</style>