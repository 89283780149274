<template>
 <div class="huifuContent" >
    <div class="huifuTouxiang" :style="{backgroundImage: `url(${touxiangPath})`}" ></div>
    <div class="huifuText">
        <span class="huifuName">{{name}}</span>  
        {{content}}
        <div class="huifuInform">
            <div class="time" :style="{visibility:''}">{{time}}</div>
            <!-- <div class="addNum">100</div>
            <div class="subNum">0</div>                     -->
        </div> 
    </div>
</div>
</template>

<script>
    export default {
        name: 'contentItem',
        data(){
            return {
                maxWidth: 45,

            }
        },
        props:{
            touxiangPath:{
                type: String,
                required: true
            },
            content:{
                type: String,
                required: true
            },
            name:{
                type: String,
                required: true
            },
            time:{
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
.time,.addNum,.subNum,.hf{
    font-size: 1.4vh;
}
.huifuInform{
    width: 41vw;
    height: 2.5vh;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    font-size: 2vh;
    font-weight: lighter;
}
.huifuContent{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    .huifuTouxiang{
        width: 4vh;
        height: 4vh;
        border-radius: 50%;
        padding: 0;
        border-width: 0;
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 0.5vh;
        background-size:contain;
    }
    .huifuName{
        height: 2vh;
        margin-right: 1.2vw;
        color: red;                    
        font-size: 1.4vh;
    }
    .huifuText{
        font-size: 2vh;
        display: inline-block;
        margin-top: 1vh;
        margin-left: 0.3vw;
        line-height: 3vh;
        width: 38.5vw;
        .huifuInform{
            width: 38vw;
            height: 2.5vh;
            display: flex;
            justify-content: space-between;
            margin-top: 2vh;
        }
    }
}
</style>