<template>
    <div class="contentPos" :style="conPosStyle"></div>
    <div class="contentLeft" :style="mainStyle">
        <div class="calendar">
            <div class="rili"><span>日历</span></div>
            <div class="selection">
                <div class="sel selection-year">
                        <div class="sub sub-year" v-show="false">{{ "<<" }}</div>
                        <span class="date-content date-year">{{date.getFullYear()}}年</span>
                        <div class="add add-year" v-show="false">{{ ">>" }}</div>
                </div>
                <div class="sel selection-month">
                        <div class="sub sub-month" v-show="false">{{ "<<" }}</div>
                        <span class="date-content date-month">{{date.getMonth()+1}}月</span>
                        <div class="add add-month" :style="{visibility: 'hidden'}">{{ ">>" }}</div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th v-for="(value, index) in weekdays" :key="index" >{{value}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, rowIndex) in [1,2,3,4,5]" :key="'row-'+rowIndex">
                        <td v-for="(value, index) in daysArray.slice((row-1)*7,row*7)" :key='date' :style="{backgroundColor:value!=null?(date.getDate()==daysArray.indexOf(value)+1-nullDays? 'blue':''):''}">{{ value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="welcome">
            <span>欢迎</span>
            <span class="welcome-content">欢迎来到鑫可可的网站!我是一个热爱技术和创新的开发者，崇尚技术知识，尤其擅长前后端各种开发技术.在这里我会定期更新我的最新项目、技术文章以及学习心得.如果您有任何问题或者建议，欢迎随时与我联系沟通!</span>
        </div>     
        <div class="resou">
            <div class="blockTitle">热搜</div>
            <div class="resouContainer">
                <div class="resouItem" v-for="(value, index) in resous" :key="index.toString+value">
                    {{ index+1 }}. {{value}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'contentLeft',
        data(){
            return {
                weekdays: ['一', '二', '三', '四', '五', '六', '日'],
                month30: [4,6,9,11],
                month31: [1,3,5,7,8,10,12],
                resous:[
                    "总书记的一本笔记",
                    "八所海事局：北部湾海域有四艘沉船",
                    "湖南一厅官被二次举报论文抄袭",
                    "我国大湖钻探探寻青藏高原自然密码",
                    "刘德华演唱会嗓子疲惫 多次挥手示意",
                    "李嘉诚家族内地资产被卷进风波",
                    "中国女子在泰遭绑架后疑似被撕票",
                    "多地出台“过紧日子”规定",
                    "水滴筹筹得90万到手3万？谣言",
                    "市监局每天办公6.5小时电话无人接",
                    "李镇江被双开 落马前一天还在赴宴",
                ],
                curDayRow: "",
                date: new Date(),
                nullDays:null,
            }
        },
        props:{
            fixed:{
                type:String,
                required:true,
            },
        },
        mounted(){
        },
        computed:{
            conPosStyle(){
                if(this.fixed=='true'){
                    return {
                       
                    }
                }
                return { display: 'none',};
            },
            mainStyle(){
                if(this.fixed=='true'){
                    return {
                        position: 'fixed',
                        top:'6vh',
                    }
                }
                return {};
            },
            daysArray(){
                var res=new Array();
                for(let i=1;i<=28;i++) res.push(i);
                if(this.month30.indexOf(this.date.getMonth()+1)>=0){
                    res.push(29);
                    res.push(30);
                }
                else if(this.month31.indexOf(this.date.getMonth()+1)>=0){
                    res.push(29);
                    res.push(30);
                    res.push(31);                
                }
                else{
                    if(this.date.getFullYear()%4==0) return res;
                    res.push(29);
                }
                let len=(this.date.getDate()-1)%7;
                let day=this.date.getDay();
                if(day==0) day=7;
                day=(day+7-len)%7;
                if(day==0) day=7;
                this.nullDays=day-1;
                for(let i=1;i<day;i++) res.unshift(null);
                return res;
            }
        },
        methods:{
            getDaysArray(year,month,xinqi,today){
                var res=new Array();
                for(let i=1;i<=28;i++) res.push(i);
                if(this.month30.indexOf(month)>=0){
                    res.push(29);
                    res.push(30);
                }
                else if(this.month31.indexOf(month)>=0){
                    res.push(29);
                    res.push(30);
                    res.push(31);                
                }
                else{
                    if(year%4==0) return res;
                    res.push(29);
                }
                let len=(today-1)%7;
                let day=xinqi;
                if(day==0) day=7;
                day=day-len;
                for(let i=1;i<day;i++) res.unshift(null);
                return res;
            }
        }
    }
</script>

<style scoped>
.th{
    background-color:red;
}
    .contentPos{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;

        width: 15vw;
        height: 94vh;
        margin-left: 8vw;
        border-radius: 3vh;
        
    }
    .resouContainer{
        height: 24vh;
        overflow-y: scroll;
        border-radius: 1vw;
    }
    .resouContainer::-webkit-scrollbar{
        height: 0;
        width: 0;
    }
    .blockTitle{
        font-size: 3vh;
    }
    .contentLeft{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 15vw;
        height: 94vh;
        border-radius: 3vh;
        margin-left: 8vw;
/*         
        position: fixed;
        top:6vh; */
        .calendar{
            width: 15vw;
            font-size: 1.5vh;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-content: flex-start;
            margin-top: 1vh;
            height: 33vh;
            border: 2px solid gray;
            border-radius: 2vh;
            &:hover{
                border-color: purple;
            }
            tr{
                height: 4vh;
            }           
            .rili{
                font-size: 3vh;
            }
            .selection{
                width: 15vw;
                display: flex;
                justify-content: space-evenly;
                font-size: 2vh;
                .date-content{
                    margin-left: 0.3vw;
                }
                .selection-year{
                    width:7vw;
                    margin-left: 1vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                span{
                    display: inline-block;
                }
                .selection-month{
                    width: 7vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .add{
                    cursor: pointer;
                    display: inline-block;
                    font-size: 2vh;
                    margin-left: 0.3vw;
                }
                .sub{
                    cursor: pointer;
                    display: inline-block;
                    font-size: 2vh;

                }
            }
        }
        .resou{
            display: flex;
            flex-wrap: wrap;
            border: 2px solid gray;
            border-radius: 2vh;
            margin-top: 1vh;
            height: 29vh;
            &:hover{
                border-color: purple;
            }
            .blockTitle{
                width: 15vw;
                text-align: center;
                margin-top: 0vh;
            }
            .resouItem{
                font-size: 2vh;
                border-width: 0.1vw;
                border-style: solid;
                border-color: black;
                margin-top: 0.01vh;
                margin-left: 0.51vw;
                width: 14vw;
                border-radius: 0.3vw;
                padding: 0.01vw;
                cursor: pointer;
            }
        }

    }    
    .welcome{
        display: flex;
        align-content: flex-start;
        margin-top: 1vh;
        font-size: 3vh;
        flex-wrap: wrap;
        justify-content: center;
        border: 2px solid gray;
        border-radius: 2vh;
        &:hover{
            border-color: purple;
        }
        .welcome-content{
            width: 14vw;
            font-size: 2vh;
            font-size:lighter;
            display: block;
            text-indent: 2vw;
            margin-top: 0.1vh;
            text-align: center;
        }

    }
    table {
        width: 90%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #ddd; /* 设置每个单元格的边框样式 */
        text-align: center;
    }
    .lineH{
        height: 0.5vh;
        width: 12vw;
        background-color: black;
        margin-top: 2vh;
    }
    .lineV{
        height: 4vh;
        width: 0.1vw;
        display: inline-block;
        background-color: black;
        margin-left: 0.3vw;
    }

    .sel{
        height: 3vh;
        line-height: 3vh;
    }
</style>