<template>
    <div class="starry-sky-container">
      <canvas ref="canvas" width="800" height="600"></canvas>
    </div>
    <div class="mainCont">
      <div class="neirong">
        <div class="henfu">
          <img src="/assets/rightArrow.png" class="leftArrow">
          <div class="henfuTexts" ref="henfuTexts">
            <div class="henfuText" v-for="(v,i) in henfuTexts">{{v}}</div>
          </div>
          <img src="/assets/rightArrow.png" class="rightArrow">
        </div>
        <div class="topB">
          <div class="topLeft">

          </div>
          <div class="topRight">

          </div>
        </div>
        <div class="items">
          <div class="twoItems">
            <div class="itemLeft"></div>
            <div class="itemRight"></div>
          </div>
        </div>
        <div class="rightB">

        </div>
      </div>
      <div class="foot">
        <a href="https://beian.miit.gov.cn/" target="_blank">
                黔ICP备2024032689号-1</a>
        <a  class="a2" href="https://beian.miit.gov.cn/" target="_blank">黔公网安备52032902000203号</a>
                                                                  
      </div>    
    </div>

  </template>
  
  <script>
  
  export default {
    data() {
      return {
        ctx: null,
        stars: [],
        starConfig: {
          minRadius: 0.5,
          maxRadius: 1
        },
        animationFrameId: null,
        henfuTexts:[
        "欢迎来到本站!","欢迎来到生活区!"
        ],
        scoV:0.03,
      };
    },
    mounted() {
      this.ctx = this.$refs.canvas.getContext('2d');
      this.createStars();
      this.animate();

      this.scrollHenfuTexts();
    },
    beforeUnmount(){
    
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId); 
      }
      this.ctx.value = null; 

    },
    methods: {
      scrollHenfuTexts() {
        var scrollContainer = this.$refs.henfuTexts;
        setInterval(() => {
          scrollContainer.scrollTop += this.scoV*(scrollContainer.scrollHeight - scrollContainer.clientHeight); // 调整滚动的步长
          //console.log(scrollContainer.scrollTop,this.scoV,(scrollContainer.scrollHeight - scrollContainer.clientHeight));
          if (scrollContainer.scrollTop >= (scrollContainer.scrollHeight - scrollContainer.clientHeight)) {
            scrollContainer.scrollTop = 0;          }
        }, 100); // 调整滚动的间隔时间，单位为毫秒
      },
      createStars() {
        for (let i = 0; i < 200; i++) {
          this.stars.push(this.createStar());
        }
      },
      createStar() {
        return {
          x: Math.random() * this.$refs.canvas.width,
          y: Math.random() * this.$refs.canvas.height,
          radius: Math.random() * (this.starConfig.maxRadius - this.starConfig.minRadius) + this.starConfig.minRadius,
          speed: Math.random() * 0.3 + 0.2,
          opacity: Math.random() * 0.05 + 0.95
        };
      },
      animate() {
        this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        this.drawStars();
        this.animationFrameId=requestAnimationFrame(this.animate);
      },
      drawStars() {
        this.stars.forEach((star, index) => {
          this.ctx.beginPath();
          this.ctx.arc(star.x, star.y, star.radius, 0, 2 * Math.PI, false);
          if(Math.random()>=0.5) this.ctx.fillStyle = `rgba(0, 0, 255, ${star.opacity})`;
          else this.ctx.fillStyle = `rgba(241, 189, 14, ${star.opacity})`;
          this.ctx.fill();
          this.updateStar(star, index);
        });
      },
      updateStar(star, index) {
        star.y -= star.speed*0.8;
        star.x +=star.speed*0.6;
        if (star.y <= 0) {
          star.y = this.$refs.canvas.height;
        }
        if(star.x>=this.$refs.canvas.width){
            star.x=0;
        }
      },
    }
  };
  </script>
  
  <style scoped>

      .foot{
        width: 100vw;
        height: 15vh;
        background-color: rgba(0,0,0,0);
        z-index: 100;
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
      }        
    a{
        display: inline-block;
        width: 100vw;
        height: 3vh;
        text-align: center;
        line-height: 3vh;
        font-size: 2vh;
        color: #ffa200;
    }
    .a2{
    }
  .henfu{
    width: 60vw;
    height: 6vh;
    border: 1.5px solid white;
    border-radius: 45px;
    display: flex;
    line-height: 4vh;
    font-size: 3vh;
    justify-content: space-between;
    align-items: center;
    .rightArrow{
      margin-right: 2vh;
    }
    .leftArrow{
      transform: rotate(180deg);
      margin-left: 2vh;
    }    
    .rightArrow,.leftArrow{
      width: 3vh;
      height: 3vh;
      border-radius: 50%;
      &:hover{
        width: 3.5vh;
        height: 3.5vh;
        cursor: pointer;
      }
    }
    .rightArrow:hover{
      margin-right: 1.5vh;
    }
    .leftArrow:hover{
      transform: rotate(180deg);
      margin-left: 1.5vh;
    }    
    .henfuTexts{
      height: 4vh;
      color:rgb(241, 237, 237);
      font-size: 2.5vh;
      overflow-y: scroll;
      width: 50vw;
      text-align: center;
      &::-webkit-scrollbar {
        display: none; 
      }  
    }

  }
  .henfu:hover{
    border: 1.5px solid rgba(253, 229, 9, 0.938);
  }
  .topB{
    width: 60vw;
    height: 30vh;
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
    .topLeft,.topRight{
      width: 29.7vw;
      height: 30vh;
      border: 1px solid white;
      border-radius: 50px;
      &:hover{
        border: 1.5px solid rgba(253, 229, 9, 0.938);
      }
    }
  }
  .rightB{
    width: 15vw;
    height: 10vh;
    margin-top: 1vh;
    margin-left: 0.5vw;
    border: 1.5px solid rgba(241, 241, 239, 0.938) ;
    border-radius: 40px;
    &:hover{
      border: 1.5px solid rgba(253, 229, 9, 0.938);
    }
  }
  .items{
    width: 44vw;
    height: 26vh;
    margin-top: 1vh;
    .twoItems{
      width: 44vw;
      height: 25vh;
      display: flex;
      justify-content: space-between;
    }
    .itemLeft, .itemRight{
      width: 21.7vw;
      height: 25vh;
      border: 1px solid white;
      border-radius: 50px;
      &:hover{
        border: 1.5px solid rgba(253, 229, 9, 0.938);

      }
    }
  }
  .mainCont{
    width: 100vw;
    position: fixed;
    left: 0vw;
    top: 10vh;
    z-index: 0;
    height: 100vh;
    .neirong{
      width: 60vw;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 100vh;
    }
  }
  .mainCont{
        overflow-y: scroll;

  }
  .mainCont::-webkit-scrollbar {
      width: 0.3vw; 
      background-color: #f5f5f5;   
  }
  .mainCont::-webkit-scrollbar-track {
      background-color: #f5f5f5; 
  }
  .mainCont::-webkit-scrollbar-thumb {
      background-color: #888; 
      border-radius: 5vh; 
  }
  .mainCont::-webkit-scrollbar-button {
      display: none; 
  }
  .starry-sky-container {
    width: 100%;
    background-color: black;
    overflow: hidden;
    position: relative;
  }
  canvas {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top:0;
    z-index: 0;
  }
  .neirong{
    margin-left: 20vw;

  }
  </style>
  