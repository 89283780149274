// store/index.js
import Vue from 'vue';
import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            userName:'',
            email:'',
            password:'',
            firstEnter: true,
        };
    },
    mutations: {
        setUserName(state,userName){
            state.userName=userName;
        },
        setPassword(state,password){
            state.password=password;
        },
        setEmail(state,email){
            state.email=email;
        },
        setFirstEnter(state,firstEnter){
            state.firstEnter=firstEnter;
        }
    },
    actions: {
        updateUserName({ commit }, userName) {
            commit('setUserName', userName);
        },
        updatePassword({ commit }, password) {
            commit('setPassword', password);
        },
        updateEmail({ commit }, email) {
            commit('setEmail', email);
        },
        updateFirstEnter({commit},firstEnter){
            commit('setFirstEnter',firstEnter);
        }
    },
    getters: {
        firstEnter: state=>state.firstEnter,
        userName: state => state.userName,
        password: state => state.password,
        email: state => state.email
    }
});

export default store;
