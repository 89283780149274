<template>
  <div class="root">
    <div ref="mainContent" class="mainContent">
        <div class="navPosition"></div>

        <RouterView></RouterView>

        <div class="foot">

        </div>
    </div>
    <navigator/>
  </div>
</template>

<script>
import contentLeft from './components/contentLeft';
import contentMiddle from './components/contentMiddle';
import contentRight from './components/contentRight';
import home from './components/home';
import liuyan from './components/liuyan.vue';
import login from './components/login.vue';
import me from './components/me';
import navigator from './components/navigator';
import zuoping from './components/zuoping.vue';
import life from './components/life.vue';
import te from './components/te.vue'
export default {
  name: 'App',
  components: {
    navigator,
    contentRight,
    contentLeft,
    contentMiddle,
    home,
    me,
    liuyan,
    zuoping,
    login,
    life,
    te,
  }
}
</script>

<style scoped>    
  *{
    user-select: none;
    color: white;
  }    
  .navPosition{
    height:6vh;
    width: 100vw;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
  .root {
    width: 100vw;           
    height: 100vh;         
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    background-color: black;
    background-size: cover;  
    background-position: center; 
    background-repeat: no-repeat; 
    
  }
  .mainContent{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
  }
  .mainContent::-webkit-scrollbar {
    width: 0.5vw; 
    background-color: #f5f5f5;   
    display: none;
  }
  .mainContent::-webkit-scrollbar-track {
    background-color: #f5f5f5; 
  }
  .mainContent::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 5vh; 
  }
  .mainContent::-webkit-scrollbar-button {
    display: none; 
  }
  .foot{
    width: 100vw;
    background-color: black;
    height: 10vh;
  }
</style>
