<template>
    <div class='topB'>
        <div class="back">
            <img class="fireInput" src="/assets/fireInpu.gif"/>
            <img class="fd" src="/assets/fd.png"/>
            <input />
            <div class="nav">
                <a href="https://www.bilibili.com/" target="_blank">B站</a>
                <a href="https://github.com/" target="_blank">github</a>
                <a href="https://www.zhihu.com/" target="_blank">知乎</a>
                <a href="https://chat.openai.com/" target="_blank">chatGPT</a>
                <a href="https://www.tmall.com/" target="_blank">天猫</a>
                <a href="https://www.iqiyi.com/" target="_blank">爱奇艺</a>
                <a href="https://www.jd.com/" target="_blank">京东</a>

            </div>
        </div>
    </div>
</template>

<script>

        export default {
        name: 'topB',
        data(){
            return {

            }
        },
        methods:{

        },
        components:{
        }
    }
</script>

<style scoped>
a{
    margin-left:1.5vw;
    color:blue;
}
.nav{
    all:unset;
    color:white;
    width:33vw;
    height:5vh;
    position:absolute;
    left: 30vw;
    top:13.5vh;
    font-size:2vh;
    z-index:20;
}
input{
    all:unset;
    color:white;
    width:31vw;
    height:5vh;
    position:absolute;
    left: 32vw;
    top:7.5vh;
    font-size:2vh;
    z-index:20;
}
.topB{
    width:100vw;
    height:22vh;
    /* background-image:url("/public/assets/fireB.gif");
    background-position:center;
    background-size:100vw 50vh;
    background-repeat:no-repeat;
    opacity:0.3 */
}
.fireInput{
    width: 35vw;
    height:6vh;
    position:absolute;
    z-index:10;
    left: 30vw;
    top: 7vh;
}
.imgFire{
    transform: rotate(180deg);
    position:absolute;
    width:100vw;
    z-index:1;
    opacity:0.4;
}
.fd{
    width:4.5vh;
    height:4.5vh;
    position:absolute;
    left: 66vw;
    top: 7.6vh;
    cursor:pointer;
    &:hover{
        width:5vh;
        height:5vh;  
    }
}
.back{
    position:relative;
    width:100vw;
    height:20vh;
    overflow:hidden;
    border:1px solid black;
}
</style>