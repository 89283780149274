<template>
    <div class="content" :style="{zIndex:zidx}">
    <pre>
第二章 常用Composition API
1. setup函数
理解：Vue3.0中一个新的额配置项，值为一个函数

2.setup是所有Composition API(组合api) “表演的舞台”

组件中所用到的：数据、方法等等，均要配置在setup中

setup函数的两种返回值:

若返回一个对象，则对象中的属性、方法，在模板中均可以直接使用。（重点关注）
若返回一个渲染函数：则可以自定义渲染内容。
注意点：

尽量不要与Vue2.x配置混用
Vue2.x配置（data ,methos, computed…）中访问到setup中的属性，方法
但在setup中不能访问到Vue2.x配置（data.methos,compued…）
如果有重名，setup优先
setup不能是一个async函数，因为返回值不再是return的对象，而是promise，模板看不到return对象中的属性
<img src="/assets/jie22.png">
1.1关于单文件组件
每个 *.vue 文件最多可以包含一个script setup。(不包括一般的 script)
这个脚本块将被预处理为组件的 setup() 函数，这意味着它将为每一个组件实例都执行。script setup 中的顶层绑定都将自动暴露给模板。
script setup 是在单文件组件 (SFC) 中使用组合式 API 的编译时语法糖。当同时使用 SFC 与组合式 API 时该语法是默认推荐。相比于普通的 script 语法，它具有更多优势：
更少的样板内容，更简洁的代码。
能够使用纯 TypeScript 声明 props 和自定义事件。这个我下面是有说明的
更好的运行时性能 (其模板会被编译成同一作用域内的渲染函数，避免了渲染上下文代理对象)。
更好的 IDE 类型推导性能 (减少了语言服务器从代码中抽取类型的工作)。
（1）基本语法：
<img src="/assets/jie23.png">
顶层的绑定会被暴露给模板
当使用 script setup 的时候，任何在 script setup 声明的顶层的绑定 (包括变量，函数声明，以及 import 导入的内容) 都能在模板中直接使用：
<img src="/assets/jie24.png">
import 导入的内容也会以同样的方式暴露。这意味着我们可以在模板表达式中直接使用导入的 action 函数，而不需要通过 methods 选项来暴露它：
<img src="/assets/jie25.png">
（2）响应式：
响应式状态需要明确使用响应式 API 来创建。和 setup() 函数的返回值一样，ref 在模板中使用的时候会自动解包：
<img src="/assets/jie26.png">

    </pre>
    </div>
</template>

<script>

export default {
        name: 'content',
        data(){
            return {
                pdfUrl: '/assets/HTML4.pdf'
            }
        },
        components:{
        },
        props:{
            zidx:Number
        }
    }
</script>

<style scoped>
pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 46vw;
}
img{
    width: 100%;
}
    .content{
        width:46vw;
        margin-left: 2vw;
        border-radius: 3vh;
        margin-top: 2vh;
        font-size: 2.5vh;
        line-height: 3.8vh;
        float: left;
        left: 0;
        top: 0;
        height: auto;
    }
</style>