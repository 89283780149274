<template>
    <div class="contentLeft">
        <div class="d1"></div>
        <div class="d2"></div>
    </div>
</template>

<script>
    export default {
        name: 'contentLeft',
        data(){
            return {
                
            }
        }
    }
</script>

<style scoped>
    .mainContent{
        width: 100vw;
        height: 100vh;
        background-color: rgba(255,0,0,0.5);
        overflow-y: scroll;
    }
    .mainContent::-webkit-scrollbar {
        width: 8px; 
        background-color: #f5f5f5; 
    }
    .mainContent::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .mainContent::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 6px; 
    }
    .mainContent::-webkit-scrollbar-button {
        display: none; 
    }
    .d1{
        width: 50vw;
        height: 20vh;
        background-color: green;
        margin-top: 0px;
    }
    .d2{
        width: 50vw;
        height: 20vh;
        background-color: yellow;
        margin-top: 70vh;
    }
</style>